import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { VDSmobileItem, VDSItem, CheckBox, Table, CardsContainer } from '@components'
import PropTypes from 'prop-types'

import s from './VDSList.module.scss'
import { useDispatch } from 'react-redux'
import { billingOperations, vdsOperations } from '@redux'
import cn from 'classnames'
import { useEffect, useState } from 'react'

export const HEAD_CELLS = [
  { label: 'server_name', value: 'server_name' },
  { label: 'pricing_plan', value: 'tariff' },
  { label: 'status', value: 'status' },
  { label: 'created', value: 'created' },
  { label: 'valid_until', value: 'valid_until' },
  { label: 'ostempl', value: 'ostempl' },
  { label: 'ip_address', value: 'ip_address' },
]

export default function VDSList({
  servers,
  rights,
  setIdForEditModal,
  setIdForDeleteModal,
  setIdForPassChange,
  setIdForReboot,
  setElForProlong,
  setIdForInstruction,
  setIdForHistory,
  goToPanelFn,
  activeServices,
  setActiveServices,
  getVDSHandler,
  isDedic,
  signal,
  setIsLoading,
}) {
  const { t } = useTranslation(['vds', 'other'])

  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' })

  const dispatch = useDispatch()

  const [unpaidItems, setUnpaidItems] = useState([])

  useEffect(() => {
    dispatch(billingOperations.getUnpaidOrders(setUnpaidItems, signal))
  }, [])

  const handleEditSubmit = (elid, values) => {
    const mutatedValues = { ...values, clicked_button: 'ok' }
    dispatch(
      vdsOperations.editVDS({
        elid,
        values: mutatedValues,
        getVDSHandler,
        signal,
        setIsLoading,
      }),
    )
  }

  const isAllActive = activeServices.length === servers.length && servers.length
  const toggleIsAllActiveHandler = () => {
    isAllActive ? setActiveServices([]) : setActiveServices(servers)
  }

  const renderHeadCells = () =>
    HEAD_CELLS.map(cell => {
      return <th key={cell.label}>{t(cell.label)}</th>
    })

  return (
    <>
      {isDesktop ? (
        <Table isItemsClickable stickyHeader vAlignTop>
          <thead>
            <tr>
              <th>
                <CheckBox
                  // className={s.check_box}
                  value={isAllActive}
                  onClick={toggleIsAllActiveHandler}
                />
              </th>
              {renderHeadCells()}
              <th></th>
            </tr>
          </thead>
          <tbody className={s.tbody}>
            {servers?.map(el => {
              return (
                <VDSItem
                  key={el.id.$}
                  server={el}
                  rights={rights}
                  activeServices={activeServices}
                  setActiveServices={setActiveServices}
                  setIdForDeleteModal={() => setIdForDeleteModal([el.id.$])}
                  setIdForEditModal={() => setIdForEditModal(el.id.$)}
                  setIdForPassChange={() => setIdForPassChange([el.id.$])}
                  setIdForReboot={() => setIdForReboot([el.id.$])}
                  setElForProlong={() => setElForProlong([el])}
                  setIdForInstruction={() => setIdForInstruction(el.id.$)}
                  setIdForHistory={() => setIdForHistory(el.id.$)}
                  goToPanelFn={() => goToPanelFn(el.id.$)}
                  handleEditSubmit={handleEditSubmit}
                  isDedic={isDedic}
                  unpaidItems={unpaidItems}
                />
              )
            })}
          </tbody>
        </Table>
      ) : (
        <>
          <label className={cn('in_row', s.check_all)}>
            <CheckBox value={isAllActive} onClick={toggleIsAllActiveHandler} />
            {t('Choose all', { ns: 'other' })}
          </label>
          <CardsContainer className={s.list}>
            {servers?.map(el => {
              if (
                el?.pricelist?.$?.includes('VDS/VPS') &&
                el?.pricelist?.$?.includes('Pro')
              ) {
                el.pricelist.$ = 'VDS/VPS «Pro»'
              } else if (
                el?.pricelist?.$?.includes('VDS/VPS') &&
                el?.pricelist?.$?.includes('2xPlatinum')
              ) {
                el.pricelist.$ = 'VDS/VPS «2xPlatinum»'
              }
              return (
                <VDSmobileItem
                  key={el.id.$}
                  server={el}
                  rights={rights}
                  activeServices={activeServices}
                  setActiveServices={setActiveServices}
                  setIdForDeleteModal={() => setIdForDeleteModal([el.id.$])}
                  setIdForEditModal={() => setIdForEditModal(el.id.$)}
                  setIdForPassChange={() => setIdForPassChange([el.id.$])}
                  setIdForReboot={() => setIdForReboot([el.id.$])}
                  setElForProlong={() => setElForProlong([el])}
                  setIdForInstruction={() => setIdForInstruction(el.id.$)}
                  setIdForHistory={() => setIdForHistory(el.id.$)}
                  goToPanelFn={() => goToPanelFn(el.id.$)}
                  handleEditSubmit={handleEditSubmit}
                  isDedic={isDedic}
                  unpaidItems={unpaidItems}
                />
              )
            })}
          </CardsContainer>
        </>
      )}
    </>
  )
}

VDSList.propTypes = {
  servers: PropTypes.arrayOf(PropTypes.object).isRequired,
  rights: PropTypes.object.isRequired,
  setIdForEditModal: PropTypes.func.isRequired,
  setIdForDeleteModal: PropTypes.func.isRequired,
  setIdForPassChange: PropTypes.func.isRequired,
  setIdForReboot: PropTypes.func.isRequired,
  setElForProlong: PropTypes.func.isRequired,
  setIdForInstruction: PropTypes.func.isRequired,
  setIdForHistory: PropTypes.func.isRequired,
  goToPanelFn: PropTypes.func.isRequired,
  activeServices: PropTypes.arrayOf(PropTypes.object).isRequired,
  setActiveServices: PropTypes.func.isRequired,
  getVDSHandler: PropTypes.func.isRequired,
}
