import { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import cn from 'classnames'
import s from './ForexItem.module.scss'

import {
  CheckBox,
  EditCell,
  ServerState,
  Options,
  AutoprolongIcon,
  TooltipWrapper,
} from '@components'
import {
  useCreateTicketOption,
  isUnpaidOrder,
  getFormatedDate,
  getItemCostValue,
  getFlagFromCountryName,
  formatCountryName,
  useOpenInNewWindow,
} from '@utils'
import * as route from '@src/routes'

export default function ForexItem({
  server,
  editNameSubmit,
  setElidForEditModal,
  setElForProlongModal,
  setElidForHistoryModal,
  setElidForDeletionModal,
  setElidForInstructionModal,
  activeServices,
  setActiveServices,
  pageRights,
  unpaidItems,
}) {
  const { t } = useTranslation(['vds', 'other', 'dns', 'crumbs', 'countries'])

  const optionsCell = useRef()
  const servernameCell = useRef()
  const checkboxCell = useRef()

  const [serverName, setServerName] = useState(server?.server_name?.$ || '')

  const editServerName = value => {
    const slicedValue = value.slice(0, 100)
    editNameSubmit({
      values: { server_name: slicedValue },
      elid: server.id?.$,
      errorCallback: () => setServerName(serverName),
    })
    setServerName(value)
  }

  const handleNewWindowNavigate = useOpenInNewWindow()

  const isToolsBtnVisible =
    Object.keys(pageRights)?.filter(
      key => key !== 'ask' && key !== 'filter' && key !== 'new',
    ).length > 0

  const isActive = activeServices?.some(service => service?.id?.$ === server?.id?.$)
  const toggleIsActiveHandler = () => {
    isActive
      ? setActiveServices(activeServices?.filter(item => item?.id?.$ !== server?.id?.$))
      : setActiveServices([...activeServices, server])
  }

  const handleToolBtnClick = fn => {
    fn()
  }
  const unpaidOption = isUnpaidOrder(server, unpaidItems)
  const createTicketOption = useCreateTicketOption(server.id.$)

  useEffect(() => {
    setServerName(server?.server_name?.$ || '')
  }, [server?.server_name?.$])

  const options = [
    unpaidOption,
    {
      label: t('instruction'),
      icon: 'Instruction',
      disabled: server?.status?.$ === '1' || !pageRights?.instruction,
      onClick: () => handleToolBtnClick(setElidForInstructionModal),
    },
    {
      label: t('prolong'),
      icon: 'Renew',
      disabled:
        server?.status?.$ === '1' || server?.status?.$ === '5' || !pageRights?.prolong,
      onClick: () => handleToolBtnClick(setElForProlongModal),
    },
    {
      label: t('edit', { ns: 'other' }),
      icon: 'NewIconEdit',
      disabled:
        !pageRights?.edit || server?.status?.$ === '1' || server?.status?.$ === '5',
      onClick: () => handleToolBtnClick(setElidForEditModal),
    },
    {
      label: t('history'),
      icon: 'HistoryTimer',
      disabled: !pageRights?.history || server?.status?.$ === '1',
      onClick: () => handleToolBtnClick(setElidForHistoryModal),
    },
    createTicketOption,
    {
      label: t('delete', { ns: 'other' }),
      icon: 'Delete',
      disabled: !server.id.$ || !pageRights?.delete || server?.status?.$ === '5',
      onClick: () => handleToolBtnClick(setElidForDeletionModal),
      isDelete: true,
    },
  ]

  const { date: expiredate } = getFormatedDate({
    date: server?.expiredate?.$,
    time: server?.i_expiretime?.$,
  })
  const { date: createdate } = getFormatedDate({
    date: server?.createdate?.$,
    time: server?.i_opentime?.$,
  })

  const itemCountry = formatCountryName(server, t)

  return (
    <tr
      onClick={e => {
        if (
          optionsCell.current.contains(e.target) ||
          checkboxCell.current.contains(e.target) ||
          servernameCell.current.contains(e.target)
        ) {
          return
        }
        handleNewWindowNavigate(e, `${route.FOREX}/${server.id.$}`, {
          state: server,
        })
      }}
      data-stop-propagation
      tabIndex={0}
    >
      <td ref={checkboxCell} data-target="checkbox">
        <CheckBox value={isActive} onClick={toggleIsActiveHandler} />
      </td>
      <td ref={servernameCell} data-target="name">
        <EditCell
          originName={serverName}
          onSubmit={editServerName}
          className={s.value}
          placeholder={t(serverName || t('server_placeholder', { ns: 'vds' }), {
            ns: 'vds',
          })}
        />
        <span className={cn('text-dark-50', 'body_s')}>ID: {server?.id?.$}</span>
      </td>
      <td>
        <div data-td-value-top data-wrap>
          {server?.pricelist?.$.replace('for', t('for', { ns: 'dns' }))
            .replace('domains', t('domains', { ns: 'dns' }))
            .replace('DNS-hosting', t('dns', { ns: 'crumbs' }))}
        </div>
        <span>{getItemCostValue(server?.cost?.$, t)}</span>
      </td>
      <td>
        <ServerState className={s.value} server={server} />
      </td>
      <td>
        {server?.datacentername && (
          <TooltipWrapper className={s.popup} content={itemCountry?.DCName}>
            <img
              src={require(`@images/countryFlags/${getFlagFromCountryName(
                itemCountry?.countryName,
              )}.png`)}
              width={20}
              height={20}
              alt={itemCountry?.countryName}
            />
          </TooltipWrapper>
        )}
      </td>
      <td>{createdate}</td>
      <td>
        <div className="in_row align_start" data-td-value-top>
          {expiredate} <AutoprolongIcon item={server} />
        </div>
        <ServerState server={server} onlyAutoDelete />
      </td>

      <td ref={optionsCell} data-target="options">
        {isToolsBtnVisible && (
          <Options options={options} dropdownClassName={s.options_dopdown} />
        )}
      </td>
    </tr>
  )
}

ForexItem.propTypes = {
  server: PropTypes.object,
  setElidForEditModal: PropTypes.func,
  setElForProlongModal: PropTypes.func,
  setElidForHistoryModal: PropTypes.func,
  setElidForInstructionModal: PropTypes.func,
  setElidForDeletionModal: PropTypes.func,
  setActiveServices: PropTypes.func,
  activeServices: PropTypes.arrayOf(PropTypes.object),
  pageRights: PropTypes.object,
}
