import { useState, useEffect } from 'react'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { InputField, Button, Modal, Cta } from '@components'
import { Formik, Form } from 'formik'
import s from './DomainsNSModal.module.scss'

const nslists = ['ns0', 'ns1', 'ns2', 'ns3', 'ns_additional']

export default function Component(props) {
  const { t } = useTranslation(['domains', 'other'])

  const { names, closeModal, NSData, NSEditDomainHandler, isOpen } = props

  const [shownElem, setShownElem] = useState(1)

  const [more, setMore] = useState(false)
  const [namesToRender, setNamesToRender] = useState(
    names.length > 1
      ? names.slice(0, 5).map(name => {
          const match = name.match(/\(([^)]+)\)/)
          return match ? match[1] : name
        })
      : names,
  )

  const handleMoreBtn = e => {
    e.preventDefault()
    setMore(!more)

    setNamesToRender(
      !more
        ? names.map(name => {
            const match = name.match(/\(([^)]+)\)/)
            return match ? match[1] : name
          })
        : names.slice(0, 5).map(name => {
            const match = name.match(/\(([^)]+)\)/)
            return match ? match[1] : name
          }),
    )
  }

  const editHandler = values => {
    const data = { ...values, sok: 'ok' }
    NSEditDomainHandler(data, NSData?.domain_id)
  }

  useEffect(() => {
    if (NSData && NSData?.ns2 && NSData?.ns2?.length !== 0) {
      setShownElem(2)
    }
    if (NSData && NSData?.ns3 && NSData?.ns3?.length !== 0) {
      setShownElem(3)
    }
    if (NSData && NSData?.ns_additional && NSData?.ns_additional?.length !== 0) {
      setShownElem(4)
    }
  }, [NSData])

  const displayedNames = more ? names : names.slice(0, 5)
  const remainingCount = names.length - displayedNames.length

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} className={s.modal}>
      <Modal.Header>
        <div>
          <p className={'headings_h2'}>{t('ns_settings')}</p>
        </div>
      </Modal.Header>
      <Modal.Body>
        {NSData?.domain_id?.split(',')?.length > 1 && (
          <div className={s.description_block}>
            <p>{t('Attention, edit few services', { ns: 'other' })}:</p>

            <p className={cn('body_m', s.domain_name)}>{namesToRender.join(', ')}</p>

            {names.length > 5 && (
              <Cta
                viewType="text"
                view="primary"
                onClick={handleMoreBtn}
                className="body_m"
              >
                {more
                  ? t('trusted_users.read_less', { ns: 'trusted_users' })
                  : t('and_more', { ns: 'other', value: remainingCount })}
              </Cta>
            )}
          </div>
        )}
        <Formik
          enableReinitialize
          initialValues={{
            ns0: NSData?.ns0 || '',
            ns1: NSData?.ns1 || '',
            ns2: NSData?.ns2 || '',
            ns3: NSData?.ns3 || '',
            ns_additional: NSData?.ns_additional || '',
          }}
          onSubmit={editHandler}
        >
          {({ errors, touched }) => {
            return (
              <Form id="domains-ns">
                <div className={s.formFieldsBlock}>
                  {nslists?.map((el, index) => {
                    if (index > shownElem) {
                      return
                    }
                    return (
                      <InputField
                        name={el}
                        key={el}
                        label={`${t(el === 'ns_additional' ? 'Additional NS' : el)}:`}
                        placeholder={t('Enter text', { ns: 'other' })}
                        className={s.input}
                        error={!!errors[el]}
                        touched={!!touched[el]}
                        infoText={t('record_format')}
                      />
                    )
                  })}

                  {shownElem + 1 !== nslists?.length && (
                    <Cta
                      viewType="text"
                      view="primary"
                      onClick={() => setShownElem(s => s + 1)}
                      className="body_m"
                    >
                      + {t('Add NS')}
                    </Cta>
                  )}
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer column>
        <Button label={t('Save', { ns: 'other' })} type="submit" form="domains-ns" />
        <Cta
          buttonType="button"
          view="secondary"
          onClick={closeModal}
          className={'body_m'}
        >
          {t('Cancel', { ns: 'other' })}
        </Cta>
      </Modal.Footer>
    </Modal>
  )
}
