import cn from 'classnames'
import { useSelector } from 'react-redux'
import { selectors } from '@redux'
import { getImageIconName, parseLang } from '@utils'
import { BtnLikeRadio, Icon, Cta } from '@components'
import { useTranslation } from 'react-i18next'

import s from './SoftwareOSBtn.module.scss'

export default function SoftwareOSBtn({
  iconName,
  label,
  imageData,
  value,
  state,
  onClick,
  disabled,
  isMarketplace,
}) {
  const darkTheme = useSelector(selectors.getTheme) === 'dark'

  const { i18n, t } = useTranslation(['cloud_vps'])

  const icon =
    iconName === 'alma'
      ? 'almalinux'
      : iconName === 'astra'
      ? 'astralinux'
      : iconName === 'noos'
      ? 'null'
      : iconName

  const osIcon = getImageIconName(icon, darkTheme)

  const currentLanguage = parseLang(i18n.language)

  const isMarketplaceTab = imageData?.$image_type === 'marketplace'

  const renderImg = () => {
    return require(`@images/soft_os_icons/${osIcon}.png`)
  }

  const renderMarketplaceImg = () => {
    return require(`@images/soft_os_icons/${osIcon}_48.png`)
  }

  const isActive = value === state && !disabled

  return isMarketplaceTab ? (
    <button
      onClick={() => onClick(value)}
      disabled={disabled}
      type="button"
      className={cn(s.marketplaceBtn, { [s.selected]: isActive })}
    >
      <span className={s.decoratedRadio}>
        <span></span>
      </span>

      <img
        className={cn(s.img, { [s.without]: icon === 'null' })}
        src={renderMarketplaceImg()}
        alt="icon"
      />

      <div className={s.flex_col}>
        <div className={s.name_wrapper}>
          {label} {imageData?.os_version?.$}
          {imageData?.$name && (
            <p className={cn('body_s_medium', s.image_name)}>{imageData?.$name}</p>
          )}
        </div>

        {imageData?.$os_distro && (
          <div className={cn('body_xs', s.os_name)}>
            {imageData?.$os_distro} {imageData?.$os_version}
          </div>
        )}
      </div>

      <Cta
        element="a"
        viewType="link"
        view="fifth"
        target="_blank"
        rel="noreferrer"
        onClick={e => e.stopPropagation()}
        className={'body_s'}
        href={`${process.env.REACT_APP_SITE_URL}${
          currentLanguage ? `/${currentLanguage}` : ''
        }/marketplace/${imageData?.$?.toLowerCase()}`}
      >
        {t('Setup guide')}
        <Icon name="InfoIconUnfilled" />
      </Cta>
    </button>
  ) : (
    <BtnLikeRadio onClick={() => onClick(value)} isActive={isActive} disabled={disabled}>
      {iconName === 'iso' ? (
        <Icon name={'Iso'} />
      ) : (
        <img
          className={cn(s.img, { [s.without]: icon === 'null' })}
          src={renderImg()}
          alt="icon"
        />
      )}

      <div className={s.flex_col}>
        <div className={s.name_wrapper}>
          {label} {imageData?.os_version?.$}
          {imageData?.$name && <p className={s.image_name}>{imageData?.$name}</p>}
        </div>

        {isMarketplace && imageData?.$os_distro && (
          <div className={cn('body_xs', s.os_name)}>
            {imageData?.$os_distro} {imageData?.$os_version}
          </div>
        )}
      </div>
    </BtnLikeRadio>
  )
}
