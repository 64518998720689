import { Button, Modal, PageTabBar, Select, Cta, NotificationMessage } from '@components'
import s from './ProlongServiceModal.module.scss'
import { useTranslation } from 'react-i18next'
import { getFormatedDate, translatePeriod } from '@utils'
import { useEffect, useReducer, useState } from 'react'
import { Form, Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { billingOperations, cartActions } from '@redux'

const PROLONG_TYPES = {
  manual: 'manual',
  auto: 'auto',
}

export default function ProlongServiceModal({
  items = [],
  closeModal,
  service,
  getItems,
}) {
  const { t } = useTranslation(['autoprolong', 'other'])
  const dispatch = useDispatch()

  const isSingle = items.length === 1
  const item = items[0]
  const itemsIdStr = items.map(el => el?.id.$).join(', ')

  const [manualState, setManualState] = useReducer((state, action) => {
    return { ...state, ...action }
  }, {})

  const [autoState, setAutoState] = useReducer((state, action) => {
    return { ...state, ...action }
  }, {})

  const { fullDateString } = getFormatedDate({
    date: item.real_expiredate?.$ ?? item?.next_payment_date?.$,
    time:
      item.i_expiretime?.$ ||
      item.expiretime?.$ ||
      item?.next_payment_date_locale_time?.$,
  })

  const [activeTab, setActiveTab] = useState(PROLONG_TYPES.manual)
  const isManual = activeTab === PROLONG_TYPES.manual

  const manualProlong = ({ values, successCallback }) =>
    dispatch(
      billingOperations.manualProlongItem({
        isSingle,
        values: { elid: itemsIdStr, ...values },
        successCallback: successCallback,
      }),
    )

  const autoProlong = ({ values, successCallback }) => {
    dispatch(
      billingOperations.autoProlongItem({
        values: { elid: itemsIdStr, ...values },
        service,
        successCallback,
      }),
    )
  }

  useEffect(() => {
    manualProlong({ successCallback: getManualProlongData })
  }, [])

  useEffect(() => {
    if (!isManual && !autoState.periodList?.length) {
      autoProlong({ successCallback: getAutoProlongData })
    }
  }, [activeTab])

  const getPeriodList = (data, key) =>
    data.slist
      ?.find(el => el.$name === key)
      ?.val?.map(el => ({
        value: el.$key,
        label: translatePeriod(el?.$, el.$key, t, true).period,
        price: translatePeriod(el?.$, el.$key, t, true).price,
      }))

  const getStoredMethodList = data =>
    data.slist
      ?.find(el => el.$name === 'stored_method')
      ?.val?.map(el => ({
        value: el.$key,
        label: t(el.$?.trim()),
      }))

  const getAutoProlongData = data => {
    const periodList = getPeriodList(data, 'autoprolong')
    const storedMethodList = getStoredMethodList(data)

    setAutoState({
      periodList,
      period: data.autoprolong?.$,
      storedMethodList,
      storedMethod: data.stored_method?.$,
    })
  }

  const getManualProlongData = data => {
    const periodList = getPeriodList(data, 'period')

    setManualState({
      periodList,
      newExpireDate: data.newexpiredate?.$ + ' ' + data.expire_time?.$,
      period: data.period?.$,
    })
  }

  const navSections = [
    {
      label: t('manual_prolong'),
      allowToRender: true,
      localValue: PROLONG_TYPES.manual,
      onLocalClick: () => {
        setActiveTab(PROLONG_TYPES.manual)
      },
    },
    {
      label: t('autoprolong'),
      allowToRender: true,
      localValue: PROLONG_TYPES.auto,
      onLocalClick: () => {
        setActiveTab(PROLONG_TYPES.auto)
      },
    },
  ]

  const onSubmitHandler = values => {
    const { period, ...autoprolongValues } = values

    isManual
      ? manualProlong({
          values: { period, clicked_button: 'basket', sok: 'ok', lang: 'en' },
          successCallback: () => {
            closeModal()

            dispatch(
              cartActions.setCartIsOpenedState({
                isOpened: true,
                redirectPath: window.location.pathname,
                manualAutoprolong: true,
              }),
            )
          },
        })
      : autoProlong({
          values: { ...autoprolongValues, clicked_button: 'ok', sok: 'ok' },
          successCallback: () => {
            getItems()
            closeModal()
          },
        })
  }

  return (
    <Modal
      isOpen={!!items.length && !!manualState.period}
      closeModal={closeModal}
      className={s.prolong_modal}
    >
      <Modal.Header>
        <div>
          <p className={'headings_h2'}>{t('server_renew_settings_title')}</p>
        </div>
      </Modal.Header>
      <Modal.Body className={s.prolong_modal_body}>
        <Formik
          enableReinitialize
          initialValues={{
            period: manualState.period,
            autoprolong: autoState.period,
            stored_method: autoState.storedMethod,
          }}
          onSubmit={onSubmitHandler}
        >
          {({ values }) => {
            return (
              <Form id={'prolong'} className={s.body}>
                {isSingle && (
                  <div className={s.prolong_info}>
                    <p>{t('active_until')}:</p>

                    <p>{fullDateString}</p>

                    <p>{t('autoprolong')}:</p>
                    <p>
                      {(item?.autoprolong?.$ && item?.autoprolong?.$ !== 'null') ||
                      (item?.prolong_period?.$ && item?.prolong_period?.$ !== 'null')
                        ? t(
                            `autoprolong_period_info.${
                              item?.autoprolong?.$.toLowerCase() ??
                              item?.prolong_period?.$.toLowerCase()
                            }`,
                          )
                        : t('Disabled')}
                    </p>
                  </div>
                )}

                {isSingle && (
                  <PageTabBar sections={navSections} activeValue={activeTab} isModal />
                )}
                {isManual || !isSingle ? (
                  <>
                    <Select
                      label={`${t('period')}:`}
                      itemsList={manualState.periodList}
                      value={manualState.period}
                      getElement={period => {
                        manualProlong({
                          values: { sv_field: 'period', period },
                          successCallback: data => {
                            setManualState({
                              newExpireDate:
                                data.newexpiredate?.$ + ' ' + data.expire_time?.$,
                              period,
                            })
                          },
                        })
                      }}
                      isColored
                    />
                    {isSingle ? (
                      <>
                        <NotificationMessage type="info">
                          {t('extended_until')}: {manualState.newExpireDate}
                        </NotificationMessage>
                      </>
                    ) : (
                      <>
                        <NotificationMessage type="warning">
                          {t('warn_prolong_few_services')}:{' '}
                          {itemsIdStr.replace(',', ', ')}
                        </NotificationMessage>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {item.autoprolong?.$ &&
                      item.autoprolong?.$ !== 'null' &&
                      values.autoprolong !== 'null' && (
                        <Cta
                          viewType="text"
                          className={s.cancel_autoprolong}
                          onClick={() => {
                            autoProlong({
                              values: { sv_field: 'autoprolong', autoprolong: 'null' },
                              successCallback: data => {
                                const storedMethodList = getStoredMethodList(data)
                                setAutoState({
                                  period: 'null',
                                  storedMethodList,
                                  storedMethod: data.stored_method?.$,
                                })
                              },
                            })
                          }}
                        >
                          {t('cancel_autoprolong')}
                        </Cta>
                      )}
                    <Select
                      label={`${t('period_autoprolong')}:`}
                      itemsList={autoState.periodList}
                      value={autoState.period}
                      getElement={period => {
                        autoProlong({
                          values: { sv_field: 'autoprolong', autoprolong: period },
                          successCallback: data => {
                            const storedMethodList = getStoredMethodList(data)
                            setAutoState({
                              period,
                              storedMethodList,
                              storedMethod: data.stored_method?.$,
                            })
                          },
                        })
                      }}
                      isColored
                    />
                    <Select
                      label={`${t('payment_method')}:`}
                      placeholder={`${t('payment_method')}`}
                      itemsList={autoState.storedMethodList}
                      value={autoState.storedMethod}
                      getElement={storedMethod => {
                        setAutoState({ storedMethod })
                      }}
                      disabled={values?.autoprolong === 'null'}
                      isColored
                    />
                    {values?.autoprolong && values?.autoprolong !== 'null' && (
                      <NotificationMessage type="info">
                        {t('next_prolong_date', {
                          date: fullDateString,
                        })}
                      </NotificationMessage>
                    )}
                  </>
                )}
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button
          label={t(isManual ? 'to_pay' : 'save')}
          size={'large'}
          type="submit"
          form={'prolong'}
        />
        <Cta
          buttonType="button"
          view="secondary"
          onClick={closeModal}
          className={'body_m'}
        >
          {t('Cancel', { ns: 'other' })}
        </Cta>
      </Modal.Footer>
    </Modal>
  )
}
