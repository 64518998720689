import { Status } from '@components'
import PropTypes from 'prop-types'
import { getInstanceMainInfo } from '@utils'
import { useTranslation } from 'react-i18next'
import { STATUS_TYPES } from '@utils/constants'

export default function InstanceStatus({ item, size }) {
  const { t } = useTranslation(['cloud_vps', 'vds', 'countries', 'filter'])

  const {
    isResized,
    displayStatus,
    isDeleting,
    isSuspended,
    isExpired,
    isActivationAfterExpired,
  } = getInstanceMainInfo(item)

  const isHintStatus =
    (isSuspended && !isExpired && !isActivationAfterExpired) || isResized
  const hintMessage = isResized ? t('resize_popup_text') : t('by_admin')

  const isBigStatus = size === 'big'

  return (
    <Status
      status={displayStatus}
      type={STATUS_TYPES[displayStatus]}
      withIcon={isHintStatus && !isDeleting}
      tooltipText={isHintStatus && hintMessage}
      bigSize={isBigStatus}
    />
  )
}

InstanceStatus.propTypes = {
  item: PropTypes.shape({
    instance_status: PropTypes.shape({
      $: PropTypes.string,
    }),
    item_status: PropTypes.shape({
      $: PropTypes.string,
    }),
    status: PropTypes.shape({
      $: PropTypes.string,
    }),
    autosuspend: PropTypes.shape({
      $: PropTypes.string,
    }),
    os_distro: PropTypes.shape({
      $: PropTypes.string,
    }),
    servername: PropTypes.shape({
      $: PropTypes.string,
    }),
    name: PropTypes.shape({
      $: PropTypes.string,
    }),
  }),
  // size: PropTypes.oneOfType(['default', 'big']),
}

InstanceStatus.defaultProps = {
  size: 'default',
}
