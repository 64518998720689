import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal, Cta, TarifCard, NotificationMessage } from '@components'
import s from './SharedHostingChangeTariffModal.module.scss'
import getFormatedDate from '@utils/getFormatedDate'
import { useSelector } from 'react-redux'
import { vhostSelectors } from '@redux/index'

export default function SharedHostingChangeTariffModal(props) {
  const { t } = useTranslation(['virtual_hosting', 'other', 'domains'])

  const {
    name,
    closeModal,
    changeTariffData,
    changeTariffInfoVhostHandler,
    changeTariffInfoData,
    changeTariffSaveVhostHandler,
    isOpen,
    itemOldDate,
  } = props

  const [tariff, setTariff] = useState(null)
  const vhostPriceList = useSelector(vhostSelectors.getVhostPricelist)?.tariflist_list

  const setTariffHandler = value => {
    if (value) {
      changeTariffInfoVhostHandler(value)
      setTariff(value)
    }
  }

  const parsePrice = price => {
    const words = price?.match(/[\d|.|\\+]+/g)
    const amounts = []

    if (words?.length > 0) {
      words?.forEach(w => {
        if (!isNaN(w)) {
          amounts.push(w)
        }
      })
    } else {
      return
    }

    return {
      sum: amounts[0],
    }
  }
  const { date: newData } = getFormatedDate({ date: changeTariffInfoData?.newDate })
  const { date: oldDate } = getFormatedDate({ date: itemOldDate })

  return (
    <Modal closeModal={closeModal} isOpen={isOpen && vhostPriceList} className={s.modal}>
      <Modal.Header>
        <div>
          <p className={'headings_h2'}>{t('change_tariff', { ns: 'virtual_hosting' })}</p>

          <span className={'body_m'}>{name}</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className={changeTariffInfoData && s.radioBlock}>
          <p>{t('choose_new_tariff')}:</p>
          <div className={s.tariffs_list}>
            {vhostPriceList
              ?.filter(({ pricelist }) => {
                return changeTariffData?.pricelist_list.some(
                  tariff => tariff.$key === pricelist.$,
                )
              })
              .map(el => {
                const pricelistId = el?.pricelist.$
                return (
                  <TarifCard
                    isColored
                    selected={tariff === pricelistId}
                    setPriceHandler={() => setTariffHandler(pricelistId)}
                    key={el?.pricelist.$}
                    tariff={el}
                  />
                )
              })}
          </div>
        </div>
        {changeTariffInfoData && (
          <>
            {parsePrice(changeTariffInfoData?.money_info)?.sum && (
              <NotificationMessage type="warning">
                {changeTariffInfoData?.money_info?.includes('You will be charged with')
                  ? t(
                      'You will be charged with {{sum}} when upgrading to a new tariff plan',
                      { sum: parsePrice(changeTariffInfoData?.money_info)?.sum },
                    )
                  : t('{{sum}} EUR will be refunded upon plan update', {
                      sum: parsePrice(changeTariffInfoData?.money_info)?.sum,
                    })}
              </NotificationMessage>
            )}

            <div className={s.compare}>
              <div className={s.compare_table}>
                <span className="text-dark-50">{t('Current tariff')}:</span>
                <span>{changeTariffInfoData?.oldpricelist}</span>

                <span className="pink">{t('New tariff')}:</span>
                <span>{changeTariffInfoData?.newpricelist}</span>

                <span className="text-dark-50">{t('Current value')}:</span>
                <span>{changeTariffInfoData?.oldprice}</span>

                <span className="pink">{t('New value')}:</span>
                <span>{changeTariffInfoData?.newprice}</span>

                <span className="text-dark-50">{t('Current end date')}:</span>
                <span>{oldDate}</span>

                <span className="pink">{t('New end date')}:</span>
                <span>{newData}</span>
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Cta
          onClick={() => changeTariffSaveVhostHandler(tariff)}
          disabled={!changeTariffInfoData}
        >
          {t('Save', { ns: 'other' })}
        </Cta>

        <Cta
          buttonType="button"
          view="secondary"
          onClick={closeModal}
          className={'body_m'}
        >
          {t('Cancel', { ns: 'other' })}
        </Cta>
      </Modal.Footer>
    </Modal>
  )
}
