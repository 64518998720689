const extractAndFormatCurrency = priceString => {
  if (priceString) {
    const value = parseFloat(priceString?.match(/[\d.]+/)[0])

    return `€ ${value.toFixed(2)}`
  }

  return ''
}

export default extractAndFormatCurrency
