import s from './DomainsTable.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import * as route from '@src/routes'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import {
  ServerState,
  CheckBox,
  Options,
  CardWrapper,
  AutoprolongIcon,
  IconButton,
} from '@components'
import {
  useCreateTicketOption,
  isUnpaidOrder,
  getFormatedDate,
  getItemCostValue,
  useOpenInNewWindow,
} from '@utils'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

export default function DomainsTableItem(props) {
  const {
    id,
    domain,
    tariff,
    cost,
    setSelctedItem,
    selected,
    el,
    editDomainHandler,
    renewDomainHandler,
    historyDomainHandler,
    whoisDomainHandler,
    NSDomainHandler,
    rights,
    unpaidItems,
  } = props
  const { t } = useTranslation(['domains', 'other', 'vds', ''])
  const mobile = useMediaQuery({ query: '(max-width: 1549px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' })

  const handleNewWindowNavigate = useOpenInNewWindow()

  const navigate = useNavigate()

  const isActive = selected?.find(elem => elem?.id.$ === el?.id.$)
  const toggleIsActiveHandler = () => setSelctedItem(!isActive, el)

  const optionsCell = useRef()
  const checkboxCell = useRef()

  const unpaidOption = isUnpaidOrder(el, unpaidItems)
  const createTicketOption = useCreateTicketOption(id)

  const options = [
    unpaidOption,
    {
      label: t('prolong', { ns: 'vds' }),
      icon: 'Renew',
      disabled: el?.status?.$ === '5' || !rights?.prolong,
      onClick: () => renewDomainHandler([el]),
    },
    {
      label: t('edit', { ns: 'other' }),
      icon: 'NewIconEdit',
      disabled: !rights?.edit,
      onClick: () => editDomainHandler(id),
    },
    {
      label: t('history', { ns: 'vds' }),
      icon: 'HistoryTimer',
      disabled: !rights?.history,
      onClick: () => historyDomainHandler(el),
    },
    {
      label: t('whois'),
      icon: 'Whois',
      disabled: !rights?.whois,
      onClick: () => whoisDomainHandler(id),
    },
    {
      label: t('ns_settings'),
      icon: 'DomainsListName',
      disabled: !rights?.ns,
      onClick: () => NSDomainHandler(id),
    },
    createTicketOption,
  ]

  const { date: expiredate } = getFormatedDate({
    date: el.real_expiredate?.$,
    time: el.i_expiretime?.$,
  })
  const { date: createdate } = getFormatedDate({
    date: el.createdate?.$,
    time: el.i_opentime?.$,
  })

  return (
    <>
      {isDesktop ? (
        <tr
          onClick={e => {
            if (
              optionsCell.current.contains(e.target) ||
              checkboxCell.current.contains(e.target)
            ) {
              return
            }
            handleNewWindowNavigate(e, `${route.DOMAINS}/${el.id.$}`, {
              state: el,
            })
          }}
          data-stop-propagation
          tabIndex={0}
        >
          <td ref={checkboxCell} data-target="checkbox">
            <CheckBox value={isActive} onClick={toggleIsActiveHandler} />
          </td>
          <td>
            <div data-td-value-top data-wrap>
              {domain}
            </div>
            <span className={cn('text-dark-50', 'body_s')}>ID: {el?.id?.$}</span>
          </td>
          <td>
            <div data-td-value-top>{tariff}</div>
            {getItemCostValue(cost, t)}
          </td>
          <td>
            <ServerState server={el} />
          </td>
          <td>{createdate}</td>
          <td>
            <div className="in_row align_start" data-td-value-top>
              {expiredate} <AutoprolongIcon item={el} />
            </div>
            <ServerState server={el} onlyAutoDelete />
          </td>
          <td ref={optionsCell} data-target="options">
            <Options
              options={options}
              useModalOnMobile
              dropdownClassName={s.options_dopdown}
            />
          </td>
        </tr>
      ) : (
        <CardWrapper className={s.item}>
          <div className={s.tools_wrapper}>
            <CheckBox
              className={s.check_box}
              value={isActive}
              onClick={toggleIsActiveHandler}
            />

            <div className="in_row">
              <IconButton
                color="third"
                size="small"
                icon="NewIconArrowUpRight"
                onClick={() => {
                  navigate(`${route.DOMAINS}/${el.id.$}`, { state: el })
                }}
                data-stop-propagation
              />
              <Options options={options} useModalOnMobile />
            </div>
          </div>

          <div className={s.columnsWithoutCheckBox}>
            <div className={s.tableBlockFirst}>
              {mobile && <div className={s.item_title}>{t('Id')}:</div>}
              <div className={cn(s.item_text, s.first_item)}>{id}</div>
            </div>
            <div className={s.tableBlockSecond}>
              {mobile && <div className={s.item_title}>{t('Domain name')}:</div>}
              <div className={cn(s.item_text, s.second_item)}>{domain}</div>
            </div>
            <div className={s.tableBlockThird}>
              {mobile && <div className={s.item_title}>{t('domain_zone')}:</div>}
              <div className={cn(s.item_text, s.third_item)}>{tariff}</div>
            </div>
            <div className={s.tableBlockFourth}>
              {mobile && <div className={s.item_title}>{t('created_at')}:</div>}
              <div className={cn(s.item_text, s.fourth_item)}>{createdate}</div>
            </div>
            <div className={s.tableBlockFourth}>
              {mobile && <div className={s.item_title}>{t('valid_until')}:</div>}
              <div className={cn(s.item_text, s.fourth_item)}>
                <div className="in_row">
                  {expiredate} <AutoprolongIcon item={el} />
                </div>
                <ServerState className={s.value} server={el} onlyAutoDelete />
              </div>
            </div>
            <div className={s.tableBlockFifth}>
              {mobile && <div className={s.item_title}>{t('status')}:</div>}
              <ServerState server={el} />
            </div>
            <div className={s.tableBlockSixth}>
              {mobile && <div className={s.item_title}>{t('Price')}:</div>}
              <div className={cn(s.item_text, s.seventh_item)}>
                {cost?.replace('Year', t('Year', { ns: 'other' }))}
              </div>
            </div>
          </div>
        </CardWrapper>
      )}
    </>
  )
}
DomainsTableItem.propTypes = {
  id: PropTypes.string,
  theme: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string,
  unread: PropTypes.bool,
  setSelctedTicket: PropTypes.func,
  selected: PropTypes.array,
  rights: PropTypes.object,
}

DomainsTableItem.defaultProps = {
  id: '',
  theme: '',
  date: '',
  status: '',
  unread: false,
  setSelctedTicket: () => null,
  selected: [],
}
