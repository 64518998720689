import { Button, Modal, NotificationMessage, PageTabBar, OsList, Cta } from '@components'
import { ErrorMessage, Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import { useEffect, useMemo, useReducer, useRef } from 'react'
import { getInstanceMainInfo } from '@utils'

import { IMAGE_TYPES } from '@utils/constants'

import s from './Modals.module.scss'

const operationSelect = 'select_boot_from_iso'

export const BootFromIsoModal = ({
  item,
  operationSystems,
  data,
  zoneList,
  closeModal,
  onSubmit,
}) => {
  const { t } = useTranslation(['cloud_vps', 'auth', 'other', 'vds'])

  const { displayName } = getInstanceMainInfo(item)

  const [state, setState] = useReducer(
    (state, action) => {
      return { ...state, ...action }
    },
    { zone: IMAGE_TYPES.public, [operationSelect]: '' },
  )

  const tabStates = useRef({})

  const navSections = useMemo(() => {
    const renderTabs = new Set(zoneList || [])

    return [...renderTabs]?.map(zone => ({
      label: t(`rescue_tab.${zone}`),
      allowToRender: true,
      localValue: zone,
      onLocalClick: () => {
        const savedState = tabStates.current[zone] || ''

        setState({
          [operationSelect]: savedState,
          zone,
          passwordType: '',
          password: '',
          ssh_keys: '',
        })
      },
    }))
  }, [data])

  const windowsOS = data?.slist
    ?.find(el => el.$name === operationSelect)
    ?.val.filter(el => el.$.includes('Windows'))

  const isWindowsOS = !!windowsOS?.find(el => el.$key === state[operationSelect])

  const validationSchema = Yup.object().shape({
    [operationSelect]: Yup.string().required(t('Is a required field', { ns: 'other' })),
  })

  const changeOSHandler = value => {
    setState({ passwordType: '' })
    setState({ [operationSelect]: value })
  }

  /* To save active tab OS on state change */
  useEffect(() => {
    if (state.zone && state[operationSelect] !== undefined) {
      tabStates.current[state.zone] = state[operationSelect] || ''
    }
  }, [state.zone, state[operationSelect]])

  const publicImages = operationSystems?.[IMAGE_TYPES.public] || []

  const ownImages = useMemo(() =>
    operationSystems?.[IMAGE_TYPES.own]?.filter(el => {
      let isImageSizeAcceptable = true

      return el.$key !== 'null' && isImageSizeAcceptable
    }),
  )

  let imagesToRender = []
  if (state.zone === IMAGE_TYPES.public) {
    imagesToRender = publicImages
  } else if (state.zone === IMAGE_TYPES.own) {
    imagesToRender = ownImages
  }

  return (
    <Modal isOpen={!!item && !!data} closeModal={closeModal} className={s.rebuild_modal}>
      <Modal.Header>
        <div>
          <p className={'headings_h2'}>
            {t(`rebuild_modal.title.${item.rebuild_action}`)}
          </p>

          <span className={'body_m'}>{displayName}</span>
        </div>
      </Modal.Header>
      <Modal.Body className={s.rebuild_modal__body}>
        <Formik
          enableReinitialize
          initialValues={{
            [operationSelect]: state?.[operationSelect],
          }}
          validationSchema={validationSchema}
          onSubmit={values => {
            const submitData = values
            submitData.zone =
              state.zone === IMAGE_TYPES.marketplace ? IMAGE_TYPES.public : state.zone

            onSubmit(submitData)
          }}
        >
          {({ values }) => {
            return (
              <Form id={'rebuild'}>
                <div className={s.body}>
                  <p className={s.body__text}>
                    {t(`rebuild_modal.text.${item.rebuild_action}`)}
                  </p>

                  <PageTabBar sections={navSections} activeValue={state.zone} isModal />
                  <p>{t(`rebuild_modal.os_description.${state.zone}`)}</p>

                  <div className={s.rebuild_list_wrapper}>
                    {imagesToRender?.length > 0 ? (
                      <div className={s.rebuild__os_list}>
                        <OsList
                          value={values[operationSelect]}
                          list={imagesToRender}
                          onOSchange={changeOSHandler}
                        />
                      </div>
                    ) : (
                      <p>{t('no_images', { ns: 'cloud_vps' })}</p>
                    )}
                    {isWindowsOS && (
                      <NotificationMessage type="warning">
                        {t('windows_os_notice')}
                      </NotificationMessage>
                    )}
                    <ErrorMessage
                      className={s.error_message}
                      name={[operationSelect]}
                      component="span"
                    />
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button label={t('Confirm')} size="small" type="submit" form={'rebuild'} />
        <Cta
          buttonType="button"
          view="secondary"
          onClick={closeModal}
          className={'body_m'}
        >
          {t('Cancel', { ns: 'other' })}
        </Cta>
      </Modal.Footer>
    </Modal>
  )
}
