import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, InputField, Select, Modal, Cta } from '@components'
import { useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import { forexOperations } from '@redux'
import { getFormatedDate, translatePeriod } from '@utils'

import s from './ForexEditModal.module.scss'
// import cn from 'classnames'

export default function ForexEditModal({ elid, name, closeModal, isOpen, getForex }) {
  const { t } = useTranslation([
    'dedicated_servers',
    'vds',
    'other',
    'crumbs',
    'affiliate_program',
    'autoprolong',
  ])
  const dispatch = useDispatch()
  const [initialState, setInitialState] = useState()

  let paymentMethodList = initialState?.paymentMethodList?.filter(item => item.$key)

  const handleEditionModal = () => {
    closeModal()
  }

  useEffect(() => {
    dispatch(forexOperations.getCurrentForexInfo(elid, setInitialState))
  }, [])

  const handleCopyText = text => {
    navigator.clipboard.writeText(text)
  }

  const handleSubmit = values => {
    const { elid } = values
    dispatch(
      forexOperations.editForex({
        values,
        elid,
        handleModal: handleEditionModal,
        successCallback: getForex,
      }),
    )
  }

  const { date: createdate } = getFormatedDate({ date: initialState?.createdate?.$ })
  const { date: expiredate } = getFormatedDate({ date: initialState?.expiredate?.$ })

  return (
    <Modal closeModal={closeModal} isOpen={isOpen} className={s.modal}>
      <Modal.Header>
        <div>
          <p className={'headings_h2'}>{t('Editing a service', { ns: 'other' })}</p>

          <span className={'body_m'}>{name}</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          initialValues={{
            elid,
            autoprolong: initialState?.autoprolong?.$ || null,
            period: initialState?.period?.$,
            server_ip: initialState?.server_ip?.$ || '',
            server_name: initialState?.server_name?.$ || '',
            server_hostname: initialState?.server_hostname?.$ || '',
            server_password: initialState?.server_password?.$ || '',
            server_user: initialState?.server_user?.$ || '',
            server_package: initialState?.serverPackageList[0]?.$ || '',
            url_rdp: initialState?.url_rdp?.$ || '',
            stored_method: initialState?.stored_method?.$ || '0',
          }}
          onSubmit={handleSubmit}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form id="forex-edit">
                <div className={s.parameters_block}>
                  <div className={s.status_wrapper}>
                    <div className={s.creation_date_wrapper}>
                      <span className={s.label}>{t('created', { ns: 'vds' })}:</span>
                      <span className={s.value}>{createdate}</span>
                    </div>
                    <div className={s.expiration_date_wrapper}>
                      <span className={s.label}>{t('valid_until', { ns: 'vds' })}:</span>
                      <span className={s.value}>{expiredate}</span>
                    </div>
                  </div>

                  <div className={s.main_block}>
                    <InputField
                      label={`${t('server_name', { ns: 'vds' })}:`}
                      name="server_name"
                      className={s.input_field_wrapper}
                      inputClassName={s.input}
                      autoComplete="off"
                      type="text"
                      value={values?.server_name}
                    />
                    <Select
                      value={values.autoprolong}
                      label={t('autoprolong')}
                      getElement={item => {
                        setFieldValue('autoprolong', item)
                        dispatch(
                          forexOperations.getCurrentForexInfo(
                            elid,
                            setInitialState,
                            item,
                          ),
                        )
                        if (item === 'null') {
                          setFieldValue('stored_method', null)
                        }
                      }}
                      isColored
                      itemsList={initialState?.autoprolongList?.map(el => {
                        return {
                          label: translatePeriod(el?.$, el.$key, t, true).period,
                          price: translatePeriod(el?.$, el.$key, t, true).price,
                          value: el.$key,
                        }
                      })}
                      className={s.select}
                      inputClassName={s.input_class}
                    />

                    <Select
                      getElement={item => {
                        setFieldValue('stored_method', item)
                      }}
                      isColored
                      label={`${t('Payment method', { ns: 'other' })}:`}
                      value={values?.stored_method}
                      itemsList={paymentMethodList?.map(el => {
                        return { label: t(el.$), value: el.$key }
                      })}
                      className={s.select}
                      inputClassName={s.input_class}
                    />

                    <InputField
                      label={`${t('ip', { ns: 'crumbs' })}:`}
                      name="server_ip"
                      className={s.input_field_wrapper}
                      inputClassName={s.input}
                      autoComplete="off"
                      type="text"
                      value={values?.server_ip}
                      disabled
                    />
                    <InputField
                      label={`${t('Hostname', { ns: 'other' })}:`}
                      name="server_hostname"
                      className={s.input_field_wrapper}
                      inputClassName={s.input}
                      autoComplete="off"
                      type="text"
                      value={values?.server_hostname}
                      disabled
                    />

                    <InputField
                      label={`${t('user_name', { ns: 'vds' })}:`}
                      name="server_user"
                      className={s.input_field_wrapper}
                      inputClassName={s.input}
                      autoComplete="off"
                      type="text"
                      value={values?.server_user}
                      disabled
                    />
                    <InputField
                      label={`${t('Password')}:`}
                      name="server_password"
                      className={s.input_field_wrapper}
                      inputClassName={s.input}
                      autoComplete="off"
                      type="text"
                      value={values?.server_password}
                      disabled
                    />
                    <InputField
                      label={`${t('tariff', { ns: 'vds' })}:`}
                      name="server_package"
                      className={s.input_field_wrapper}
                      inputClassName={s.input}
                      autoComplete="off"
                      type="text"
                      value={values?.server_package}
                      disabled
                    />

                    <InputField
                      name="url_rdp"
                      label={`${t('Connection URL', { ns: 'other' })} :`}
                      labelTooltipPlace="bottom"
                      value={values?.url_rdp}
                      placeholder={values.url_rdp}
                      className={s.input_field_wrapper}
                      autoComplete="off"
                      iconRight="NewIconCopy"
                      inputIconTooltip={t('about_section.link_copied', {
                        ns: 'affiliate_program',
                      })}
                      onIconClick={() => handleCopyText(values.url_rdp)}
                      disabled
                    />
                  </div>
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button
          label={t('Save', { ns: 'other' })}
          type="submit"
          form="forex-edit"
          className={s.buy_btn}
        />

        <Cta
          buttonType="button"
          view="secondary"
          onClick={closeModal}
          className={'body_m'}
        >
          {t('Cancel', { ns: 'other' })}
        </Cta>
      </Modal.Footer>
    </Modal>
  )
}
