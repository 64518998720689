import s from './DeskWrapper.module.scss'
import cn from 'classnames'
import { Loader } from '@components'
import { useDeskContext } from './DeskContext'

export const ScrollContainer = ({ children, isLoading, className, contentClassName }) => {
  const { isDesktop } = useDeskContext()

  return (
    <div className={cn(s.content_wrapper, className)}>
      <div
        className={cn(s.content, contentClassName)}
        data-scroll-container={isDesktop ? 'true' : null}
      >
        {children}
      </div>
      {isLoading && <Loader local shown={isLoading} />}
    </div>
  )
}
