const SvgComponent = props => (
  <svg
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_5018_9229)">
      <path
        d="M33.0007 48.6663C41.1008 48.6663 47.6673 42.0999 47.6673 33.9997C47.6673 25.8995 41.1008 19.333 33.0007 19.333C24.9005 19.333 18.334 25.8995 18.334 33.9997C18.334 42.0999 24.9005 48.6663 33.0007 48.6663Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28 37.2C28 38.9673 29.4924 40.4 31.3333 40.4H34.6667C36.5076 40.4 38 38.9673 38 37.2C38 35.4327 36.5076 34 34.6667 34H31.3333C29.4924 34 28 32.5673 28 30.8C28 29.0327 29.4924 27.6 31.3333 27.6H34.6667C36.5076 27.6 38 29.0327 38 30.8M33 26V42"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.75126 18.9998C11.2088 14.7432 14.7434 11.2086 19 8.75106C23.2565 6.29354 28.0849 4.99977 33 4.99977"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 60.9998C28.085 60.9998 23.2565 59.706 19 57.2485C14.7434 54.791 11.2088 51.2563 8.75126 46.9998"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.2487 18.9998C59.7062 23.2563 61 28.0847 61 32.9998C61 37.9148 59.7062 42.7432 57.2487 46.9998"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_5018_9229">
        <rect width="64" height="64" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
export default SvgComponent
