import { Infinity } from '@src/images'
import cn from 'classnames'
import { useSelector } from 'react-redux'
import { cloudVpsSelectors } from '@src/Redux'
import { checkIfHasWindows } from '@src/utils'
import { Icon } from '@src/Components'
import { LIMITED_CLOUD_TARIFFS } from '@utils/constants'
import { useTranslation } from 'react-i18next'

import s from './TariffCard.module.scss'

export default function TariffCard({
  tariff,
  onClick,
  price,
  active,
  disabled,
  isSoldOut,
  showHourPrice,
  isColored,
  isModal,
}) {
  const windowsTag = useSelector(cloudVpsSelectors.getWindowsTag)
  const hasWindows = checkIfHasWindows(tariff, windowsTag)
  const { t } = useTranslation(['cloud_vps'])

  const cpu = tariff.detail.find(el => el.name.$.toLowerCase().includes('cpu'))?.value.$
  const memory = tariff.detail.find(el => el.name.$.toLowerCase() === 'memory').value.$

  const disk = tariff.detail.find(el => el.name.$.toLowerCase() === 'disk space').value.$

  const portSpeed = tariff.detail.find(el => el.name.$.toLowerCase() === 'port speed')
    ?.value.$

  const isLimited = LIMITED_CLOUD_TARIFFS.includes(tariff.id.$)

  const hourPrice = tariff.prices.price.find(el => el.period.$ === 'hour').cost.$

  return (
    <li
      className={cn('box_styles', s.tariff_item, {
        [s.selected]: active,
        [s.disabled]: disabled,
        [s.cardColor]: isColored,
        [s.modal]: isModal,
      })}
    >
      <button
        className={s.tariff_btn}
        type="button"
        onClick={() => {
          onClick(tariff.id.$)
        }}
        disabled={disabled}
      >
        <div className={s.tariff_title}>
          {tariff.title.$}

          {isSoldOut && (
            <div className={s.soldout_label}>
              <Icon name="Renew" width="12px" height="12px" strokeWidth="2px" />
              sold out
            </div>
          )}
        </div>

        <div className={cn('body_s', s.tariff_parameters)}>
          {isSoldOut && (
            <p className={cn(s.sold_out_text, 'body_s')}>
              {t('activation_time')}
              <Icon name="Info" width="14px" height="14px" />
            </p>
          )}
          <div className={s.tariff_row}>
            <span className={s.parameter_label}>CPU</span>
            <span>{cpu}</span>
          </div>
          <div className={s.tariff_row}>
            <span className={s.parameter_label}>RAM</span>
            <span>{memory}</span>
          </div>
          <div className={s.tariff_row}>
            <span className={s.parameter_label}>NVMe</span>
            <span>{disk}</span>
          </div>
          <div className={s.tariff_row}>
            <span className={s.parameter_label}>Speed</span>
            <span>{portSpeed}</span>
          </div>
          <div className={s.tariff_row}>
            <span className={s.parameter_label}>Bandwidth</span>
            <span>
              <Infinity className={s.infinity} />
            </span>
          </div>
          <div className={s.tariff_row}>
            <span className={s.parameter_label}>OS</span>
            <span>{hasWindows ? 'Windows & Linux' : 'Linux'}</span>
          </div>
        </div>

        <div>
          <span className={s.tariff_price}>€{price}</span>
          {showHourPrice && <span className={s.hour_price}>€{hourPrice}/hour</span>}
        </div>
      </button>

      {isLimited && (
        <div className={s.limited_label}>
          <span className={s.limited_label__subtext}>{t('limited_label__subtext')}</span>
          <span className={s.limited_label__text}>{t('limited_label__text')}</span>
        </div>
      )}
    </li>
  )
}
