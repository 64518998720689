import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import PropTypes from 'prop-types'
import cn from 'classnames'
import s from './ForexList.module.scss'
import ForexItem from '../ForexItem/ForexItem'
import ForexMobileItem from '../ForexMobileItem/ForexMobileItem'
import { CheckBox, CardsContainer, Table } from '@components'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { billingOperations } from '@src/Redux'

export const HEAD_CELLS = [
  { label: 'server_name', value: 'server_name' },
  { label: 'pricing_plan', value: 'tariff' },
  { label: 'status', value: 'status' },
  { label: 'datacenter', value: 'datacenter' },
  { label: 'created', value: 'created' },
  { label: 'valid_until', value: 'valid_until' },
]

export default function ForexList({
  emptyFilter,
  forexList,
  editNameSubmit,
  setElidForEditModal,
  setElForProlongModal,
  setElidForHistoryModal,
  setElidForDeletionModal,
  setElidForInstructionModal,
  activeServices,
  setActiveServices,
  pageRights,
  signal,
}) {
  const dispatch = useDispatch()

  const { t } = useTranslation([
    'vds',
    'other',
    'dedicated_servers',
    'domains',
    'access_log',
  ])
  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' })

  const [unpaidItems, setUnpaidItems] = useState([])

  useEffect(() => {
    dispatch(billingOperations.getUnpaidOrders(setUnpaidItems, signal))
  }, [])

  if (forexList) {
    if (forexList.length === 0 && emptyFilter) {
      return (
        <div className={s.no_results_wrapper}>
          <p className={s.no_results_text}>{t('nothing_found', { ns: 'access_log' })}</p>
        </div>
      )
    }

    if (forexList.length === 0 && forexList) {
      return (
        <div className={s.no_service_wrapper}>
          <img
            src={require('@images/services/forexbox.webp')}
            alt="forex"
            className={s.forex_img}
          />
          <p className={s.no_service_title}>
            {t('YOU DO NOT HAVE A FOREX SERVER YET', { ns: 'other' })}
          </p>
          <p className={s.no_service_description}>
            {t('no services forex description', { ns: 'other' })}
          </p>
        </div>
      )
    }
  }

  const isAllActive = activeServices?.length === forexList?.length
  const toggleIsAllActiveHandler = () => {
    isAllActive ? setActiveServices([]) : setActiveServices(forexList)
  }

  const renderHeadCells = () =>
    HEAD_CELLS.map(cell => {
      return <th key={cell.label}>{t(cell.label)}</th>
    })

  return (
    <>
      {isDesktop ? (
        <Table isItemsClickable stickyHeader vAlignTop>
          <thead>
            <tr>
              <th>
                <CheckBox value={isAllActive} onClick={toggleIsAllActiveHandler} />
              </th>
              {renderHeadCells()}
              <th></th>
            </tr>
          </thead>
          <tbody className={s.tbody}>
            {forexList?.map(el => {
              return (
                <ForexItem
                  key={el.id.$}
                  server={el}
                  editNameSubmit={editNameSubmit}
                  setElidForEditModal={() => setElidForEditModal(el.id.$)}
                  setElForProlongModal={() => setElForProlongModal([el])}
                  setElidForHistoryModal={() => setElidForHistoryModal(el.id.$)}
                  setElidForDeletionModal={() => setElidForDeletionModal([el.id.$])}
                  setElidForInstructionModal={() => setElidForInstructionModal(el.id.$)}
                  activeServices={activeServices}
                  setActiveServices={setActiveServices}
                  pageRights={pageRights}
                  unpaidItems={unpaidItems}
                />
              )
            })}
          </tbody>
        </Table>
      ) : (
        <>
          <label className={cn('in_row', s.check_all)}>
            <CheckBox value={isAllActive} onClick={toggleIsAllActiveHandler} />
            {t('Choose all', { ns: 'other' })}
          </label>
          <CardsContainer>
            {forexList?.map(el => {
              return (
                <ForexMobileItem
                  key={el.id.$}
                  server={el}
                  setElidForEditModal={() => setElidForEditModal(el.id.$)}
                  setElForProlongModal={() => setElForProlongModal([el])}
                  setElidForHistoryModal={() => setElidForHistoryModal(el.id.$)}
                  setElidForDeletionModal={() => setElidForDeletionModal([el.id.$])}
                  setElidForInstructionModal={() => setElidForInstructionModal(el.id.$)}
                  activeServices={activeServices}
                  setActiveServices={setActiveServices}
                  pageRights={pageRights}
                  unpaidItems={unpaidItems}
                />
              )
            })}
          </CardsContainer>
        </>
      )}
    </>
  )
}

ForexList.propTypes = {
  forexList: PropTypes.arrayOf(PropTypes.object),
  setElidForEditModal: PropTypes.func,
  emptyFilter: PropTypes.bool,
  setElForProlongModal: PropTypes.func,
  setElidForHistoryModal: PropTypes.func,
  setElidForInstructionModal: PropTypes.func,
  setElidForDeletionModal: PropTypes.func,
  setActiveServices: PropTypes.func,
  activeServices: PropTypes.arrayOf(PropTypes.object),
  pageRights: PropTypes.object,
}
