import { useState, useEffect, useRef } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import cn from 'classnames'
import BurgerMenu from './BurgerMenu/BurgerMenu'
import { userSelectors, authOperations } from '@redux'
import {
  BalanceBtn,
  NotificationBtn,
  LangBtn,
  Icon,
  CertificateModal,
  HeaderSelect,
} from '@components'
import * as routes from '@src/routes'
import { useOutsideAlerter, useIsTouchDevice } from '@utils'
import { useMediaQuery } from 'react-responsive'

import s from './Header.module.scss'
import { useRightsContext } from '@src/context/RightsContext'

export default function Header() {
  const { t } = useTranslation('container')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { categories_rights, menuLists, isUseCertificate } = useRightsContext()

  const profileMenuListToRender = menuLists.profileMenuList.filter(
    item => item.allowedToRender,
  )

  const userItems = useSelector(userSelectors.getUserItems)

  const notifications = userItems?.messages_count ? userItems?.messages_count : 0

  // const [notifications, setNotifications] = useState(mesAmount)

  // const handleRemoveNotif = () => {
  //   let newNotifications = notifications === 0 ? 0 : notifications - 1
  //   setNotifications(newNotifications)
  // }

  const userTickets = useSelector(userSelectors.getUserTickets)
  const areNewTickets = userTickets.some(ticket => ticket.tstatus.$ === 'New replies')

  const { $realname, $email, $avatar_src, $avatar } = useSelector(
    userSelectors.getUserInfo,
  )

  const [isMenuOpened, setIsMenuOpened] = useState(false)

  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isHoveredButton, setIsHoveredButton] = useState(false)
  const [isHoveredDropdown, setIsHoveredDropdown] = useState(false)

  const accountDropdown = useRef(null)
  const closingTimeoutRef = useRef(null)
  const isTouchDevice = useIsTouchDevice()

  const dropdownHandler = () => {
    if (isTouchDevice) {
      setIsDropdownOpen(prev => !prev)
    } else {
      setIsDropdownOpen(true)
    }
  }

  useOutsideAlerter(accountDropdown, isDropdownOpen, dropdownHandler)

  const handleItemClick = routeName => {
    setIsDropdownOpen(false)

    navigate(routeName)
  }

  const handleMouseEnter = () => {
    if (!isTouchDevice) {
      setIsHoveredButton(true)
      setIsDropdownOpen(true)
      /* Clear the closing timer if the cursor returns to the button */
      clearTimeout(closingTimeoutRef.current)
    }
  }

  const handleButtonMouseLeave = () => {
    if (!isTouchDevice) {
      setIsHoveredButton(false)

      /* Set a closing timer if the cursor is not over the dropdown */
      closingTimeoutRef.current = setTimeout(() => {
        if (!isHoveredDropdown) {
          setIsDropdownOpen(false)
        }
      }, 100)
    }
  }

  const handleDropdownMouseEnter = () => {
    setIsHoveredDropdown(true)

    /* If hovering over the dropdown, cancel the closing timer */
    clearTimeout(closingTimeoutRef.current)
  }

  const handleDropdownMouseLeave = () => {
    setIsHoveredDropdown(false)

    closingTimeoutRef.current = setTimeout(() => {
      if (!isHoveredButton) {
        setIsDropdownOpen(false)
      }
    }, 100)
  }

  const handleClick = () => {
    setIsMenuOpened(!isMenuOpened)
  }

  const logOut = () => {
    // dispatch(authActions.isLogined(false))
    setIsDropdownOpen(false)
    dispatch(authOperations.logout())
  }

  /* Hardcoded value of full height and header height without logo for smooth view */
  const HEADER_HEIGHT = 115
  const MIN_HEADER_HEIGHT = 67
  const MAX_SCROLL = HEADER_HEIGHT - MIN_HEADER_HEIGHT

  const mobile = useMediaQuery({ query: '(max-width: 767.98px)' })
  const isNotDesktop = useMediaQuery({ query: '(max-width: 1023px)' })

  const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    const container = document.getElementById('container')

    const handleScroll = () => {
      setScrollY(container.scrollTop)
    }

    container.addEventListener('scroll', handleScroll)

    return () => {
      container.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const isScrolled = scrollY > 50

  useEffect(() => {
    !isNotDesktop && setIsMenuOpened(false)
  }, [isNotDesktop])

  return (
    <>
      <header
        className={cn(s.main_header, {
          [s.scrolled]: isScrolled,
        })}
        style={{
          transform: mobile && `translateY(-${Math.min(scrollY, MAX_SCROLL)}px)`, // Move the header down based on scroll
        }}
      >
        <Link
          to={routes.HOME}
          className={s.logo_link}
          style={{
            transform: mobile && `translateY(-${scrollY * 0.5}px)`,
          }}
        >
          <Icon name="Logo" className={s.logo} />
        </Link>

        <div
          className={cn(s.list, {
            [s.scrolled]: isScrolled,
          })}
        >
          <div className={s.long_btn_wrapper}>
            <HeaderSelect isScrolled={isScrolled} />

            <BalanceBtn isHeader />

            <div className={cn(s.lang_item)}>
              <LangBtn isScrolled={isScrolled} mainType />
            </div>
          </div>

          <div className={s.short_btn_wrapper}>
            {categories_rights.isEnvelopeAllowed && (
              <div
                className={cn(s.item, s.support, {
                  [s.active_notification]: areNewTickets,
                  [s.no_notif]: notifications === 0,
                })}
              >
                <NavLink
                  to={routes.SUPPORT}
                  className={({ isActive }) =>
                    cn(s.btn, s.support, {
                      [s.active]: isActive,
                    })
                  }
                >
                  <Icon
                    name="SupportIcon"
                    svgwidth="20"
                    svgheight="20"
                    className={s.support}
                  />
                </NavLink>
              </div>
            )}

            <NotificationBtn isHeader isScrolled={isScrolled} isMobile={mobile} />

            <div
              className={cn(s.wrapper, {
                [s.opened]: isDropdownOpen,
                [s.scrolled]: isScrolled,
              })}
            />

            <div
              className={cn(s.profile_item, { [s.opened]: isDropdownOpen })}
              ref={accountDropdown}
            >
              <button
                className={s.btn}
                onClick={dropdownHandler}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleButtonMouseLeave}
              >
                {$avatar ? (
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}${$avatar_src}`}
                    className={s.avatar_img}
                    alt="Avatar"
                  />
                ) : (
                  <Icon name="User" className={s.user_icon} />
                )}
              </button>

              {isDropdownOpen && (
                <div
                  className={cn(s.profile_dropdown__wrapper, {
                    // [s.disappearing]: isDisappearing,
                  })}
                  onMouseEnter={handleDropdownMouseEnter}
                  onMouseLeave={handleDropdownMouseLeave}
                >
                  <ul className={s.profile_list}>
                    <li className={s.profile_list_username_item}>
                      <div>
                        <p className={s.user_name}>{$realname}</p>
                        <p className={s.user_email}>{$email}</p>
                      </div>
                    </li>

                    {profileMenuListToRender.map((item, index) => {
                      return (
                        <li key={index} className={s.profile_list_item}>
                          {item.routeName ? (
                            <NavLink
                              to={item.routeName}
                              onClick={() => handleItemClick(item.routeName)}
                            >
                              {t(item.name)}
                            </NavLink>
                          ) : (
                            <button onClick={item?.onClick}>{t(item.name)}</button>
                          )}
                        </li>
                      )
                    })}
                    <li className={cn(s.profile_list_item, s.exit_list_item)}>
                      <button type="button" onClick={logOut}>
                        {t('profile.log_out')}
                      </button>
                    </li>
                  </ul>
                </div>
              )}
            </div>

            <button
              className={cn(s.header_menuburger, s.hamburger_spin, {
                [s.opened]: isMenuOpened,
              })}
              type="button"
              onClick={handleClick}
            >
              <span className={s.hamburger_box}>
                <span className={s.hamburger_inner}></span>
              </span>
            </button>
          </div>
        </div>
      </header>

      <BurgerMenu
        isOpened={isMenuOpened}
        classes={cn(s.burger_menu, { [s.opened]: isMenuOpened })}
        controlMenu={handleClick}
        isScrolled={isScrolled}
        isMobile={mobile}
      />

      {isUseCertificate && <CertificateModal />}
    </>
  )
}
