import { Icon } from '@components'
import s from './HidePassword.module.scss'
import cn from 'classnames'
import { useState } from 'react'

export default function HidePassword({
  className,
  isBtnDisabled = false,
  label,
  labelClassName,
}) {
  const [isVisible, setIsVisible] = useState(false)

  const visibilitiToggleHandler = () => setIsVisible(p => !p)

  return (
    <div className={s.wrapper}>
      <button
        className={cn(s.copy_btn, className)}
        type="button"
        disabled={isBtnDisabled}
        onClick={visibilitiToggleHandler}
      >
        <span className={cn(labelClassName, { [s.blured]: !isVisible })}>{label}</span>
        <Icon
          name={isVisible ? 'NewIconEyeHide' : 'NewIconEye'}
          className={cn(s.copy_icon, {
            [s.disabled]: isBtnDisabled,
            [s.visible]: isVisible,
          })}
        />
      </button>
    </div>
  )
}
