import { useEffect } from 'react'
import useDisableScroll from './useDisableScroll'

export default function useShowDropdownInView({ condition, btn, dropdown }) {
  useDisableScroll({ condition, btn })

  useEffect(() => {
    if (condition && dropdown.current) {
      const container =
        btn.current?.closest('[data-scroll-container]') ||
        document.getElementById('container')
      const containerRect = container.getBoundingClientRect()
      const btnRect = btn.current.getBoundingClientRect()
      const dropdownRect = dropdown.current.getBoundingClientRect()

      dropdown.current.style.left = btnRect.left - dropdownRect.width - 5 + 'px'

      const compareValue = Math.min(
        containerRect.bottom - btnRect.top,
        window.innerHeight - btnRect.top,
      )

      if (compareValue < dropdownRect.height) {
        dropdown.current.style.top = btnRect.bottom - dropdownRect.height + 'px'
      } else {
        dropdown.current.style.top = btnRect.top + 'px'
      }
    }
  }, [condition])
}
