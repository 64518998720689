import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { AuthPage } from '@pages'
import {
  Cart,
  Container,
  EmailConfirmation,
  PasswordChange,
  Portal,
  CartFromSite,
  MainEmailConfirmation,
  SuccessPayment,
  ErrorPayment,
  ModalCreatePayment,
} from '@components'
import { cartSelectors, billingSelectors, userSelectors } from '@redux'
import * as route from '@src/routes'
import {
  VDSPageLazy,
  VDSLazy,
  VDSOrderLazy,
  VDSipLazy,
  SharedHostingLazy,
  SharedHostingOrderLazy,
  SiteCareLazy,
  SiteCareOrderLazy,
  VPNLazy,
  VPNOrderLazy,
  DomainsPageLazy,
  DomainOrderPageLazy,
  DomainContactInfoPageLazy,
  DomainsNsPageLazy,
  DedicatedServersPageLazy,
  DedicOrderPageLazy,
  DedicIPpageLazy,
  FTPPageLazy,
  FTPOrderPageLazy,
  DNSPageLazy,
  DNSOrderPageLazy,
  ForexPageLazy,
  ForexOrderPageLazy,
  UserSettingsPageLazy,
  PhoneVerificationPageLazy,
  SocialNetAddPageLazy,
  AffiliateProgramPageLazy,
  ErrorPageLazy,
  PaymentSavedPageLazy,
  SupportPageLazy,
  OpenedTickerPageLazy,
  BillingPageLazy,
  PaymentProcessingPageLazy,
  DedicatedPageLazy,
  CloneOrderPageLazy,
  CloudVPSPageLazy,
  CloudVPSInstancesPageLazy,
  CloudVPSSSHKeysPageLazy,
  CloudVPSImagesPageLazy,
  CloudVpsImageDetailesPageLazy,
  CreateInstancePageLazy,
  CloudInstanceItemPageLazy,
  InstanceDetailsOverviewLazy,
  InstanceMetricsLazy,
  InstanceNetworkTrafficLazy,
  InstanceSnapshotsLazy,
  InstanceBackupsLazy,
  InstanceBackupsSchedulesLazy,
  DedicItemPageLazy,
  DedicDetailsOverviewLazy,
  DedicHistoryOverviewLazy,
  DashboardPageLazy,
  SharedHostingItemPageLazy,
  SharedHostingDetailsOverviewLazy,
  ForexItemPageLazy,
  ForexDetailsOverviewLazy,
  ForexHistoryOverviewLazy,
  VDSItemPageLazy,
  VDSDetailsOverviewLazy,
  VDSHistoryOverviewLazy,
  DomainsItemPageLazy,
  DomainHistoryOverviewLazy,
  DomainWhoisOverviewLazy,
  DomainDetailsOverviewLazy,
} from './LazyRoutes'
// import { FIRST_MONTH_HOSTING_DISCOUNT_ID } from '@utils/constants'
import { navigateIfFromSite } from '@utils'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(LocalizedFormat)

const Component = ({ fromPromotionLink }) => {
  const navigate = useNavigate()

  const cartState = useSelector(cartSelectors.getCartState)

  const isModalCreatePaymentOpened = useSelector(
    billingSelectors.getIsModalCreatePaymentOpened,
  )
  const { $timezone: userTimeZone } = useSelector(userSelectors.getUserInfo)

  useEffect(() => {
    if (userTimeZone === 'SYSTEM') {
      dayjs.tz.setDefault('Europe/Kyiv')
    } else if (userTimeZone) {
      dayjs.tz.setDefault(userTimeZone)
    }
  }, [userTimeZone])

  useEffect(() => {
    /**
     * navigates to specific service order page if we have info in localStorage
     */
    const cartDataFromSite = localStorage.getItem('site_cart')
    if (cartDataFromSite) {
      navigateIfFromSite(cartDataFromSite, navigate)
    }
  }, [])

  if (location.pathname === route.VDS) {
    return <Navigate to={route.VPS} replace />
  }

  if (location.pathname === route.VDS_ORDER) {
    return <Navigate to={route.VPS_ORDER} replace />
  }

  if (location.pathname === route.VDS_IP) {
    return <Navigate to={route.VPS_IP} replace />
  }

  if (location.pathname === route.CHANGE_PASSWORD) {
    return (
      <Routes>
        <Route
          path={route.CHANGE_PASSWORD}
          element={<AuthPage children={<PasswordChange />} />}
        />
      </Routes>
    )
  }

  return (
    <Container fromPromotionLink={fromPromotionLink}>
      <Routes>
        <Route path={route.LOGIN} element={<Navigate to={route.HOME} replace={true} />} />

        <Route path={route.HOME} element={<DashboardPageLazy />} />

        <Route
          path={route.REGISTRATION}
          element={<Navigate to={route.HOME} replace={true} />}
        />
        <Route
          path={route.SERVICES}
          element={<Navigate to={route.HOME} replace={true} />}
        />
        <Route path={route.VPS} element={<VDSPageLazy />}>
          <Route index element={<VDSLazy />} />
        </Route>

        <Route path={`${route.VPS}/:id`} element={<VDSItemPageLazy />}>
          <Route index element={<VDSDetailsOverviewLazy />} />
          <Route path="history" element={<VDSHistoryOverviewLazy />} />
        </Route>

        <Route path={route.VPS_ORDER} element={<VDSOrderLazy />} />
        <Route path={route.VPS_IP} element={<VDSipLazy />} />
        <Route path={route.SHARED_HOSTING} element={<SharedHostingLazy />} />

        <Route
          path={`${route.SHARED_HOSTING}/:id`}
          element={<SharedHostingItemPageLazy />}
        >
          <Route index element={<SharedHostingDetailsOverviewLazy />} />
        </Route>

        <Route path={route.SHARED_HOSTING_ORDER} element={<SharedHostingOrderLazy />} />
        <Route path={route.WORDPRESS} element={<SharedHostingLazy />} />
        <Route
          path={route.WORDPRESS_ORDER}
          element={<SharedHostingOrderLazy type="wordpress" />}
        />
        <Route path={route.SITE_CARE} element={<SiteCareLazy />} />
        <Route path={route.SITE_CARE_ORDER} element={<SiteCareOrderLazy />} />
        <Route path={route.VPN} element={<VPNLazy />} />
        <Route path={route.VPN_ORDER} element={<VPNOrderLazy />} />
        <Route path={route.DOMAINS} element={<DomainsPageLazy />} />
        <Route path={route.DOMAINS_ORDERS} element={<DomainOrderPageLazy />} />
        <Route
          path={route.DOMAINS_CONTACT_INFO}
          element={<DomainContactInfoPageLazy />}
        />
        <Route path={route.DOMAINS_NS} element={<DomainsNsPageLazy />} />
        <Route
          path={route.DOMAINS_TRANSFER_ORDERS}
          element={<DomainOrderPageLazy transfer={true} />}
        />
        <Route
          path={route.DOMAINS_TRANSFER_CONTACT_INFO}
          element={<DomainContactInfoPageLazy transfer={true} />}
        />
        <Route
          path={route.DOMAINS_TRANSFER_NS}
          element={<DomainsNsPageLazy transfer={true} />}
        />

        <Route path={`${route.DOMAINS}/:id`} element={<DomainsItemPageLazy />}>
          <Route index element={<DomainDetailsOverviewLazy />} />
          <Route path="history" element={<DomainHistoryOverviewLazy />} />
          <Route path="whois" element={<DomainWhoisOverviewLazy />} />
        </Route>

        <Route path={`${route.DEDICATED_SERVERS}`} element={<DedicatedPageLazy />}>
          <Route index element={<DedicatedServersPageLazy />} />
          <Route path="vds" element={<VDSLazy isDedic />} />
        </Route>

        <Route path={`${route.DEDICATED_SERVERS}/vds/:id`} element={<VDSItemPageLazy />}>
          <Route index element={<VDSDetailsOverviewLazy />} />
          <Route path="history" element={<VDSHistoryOverviewLazy />} />
        </Route>

        <Route path={`${route.DEDICATED_SERVERS}/:id`} element={<DedicItemPageLazy />}>
          <Route index element={<DedicDetailsOverviewLazy />} />
          <Route path="history" element={<DedicHistoryOverviewLazy />} />
        </Route>

        <Route path={route.CLOUD_VPS} element={<CloudVPSPageLazy />}>
          <Route index element={<CloudVPSInstancesPageLazy />} />
          <Route path="ssh_keys" element={<CloudVPSSSHKeysPageLazy />} />
          <Route path="images" element={<CloudVPSImagesPageLazy />} />
        </Route>

        <Route
          path={`${route.CLOUD_VPS}/images/:elid`}
          element={<CloudVpsImageDetailesPageLazy pageList="images" />}
        />

        <Route
          path={route.CLOUD_VPS_CREATE_INSTANCE}
          element={<CreateInstancePageLazy />}
        />

        <Route path={`${route.CLOUD_VPS}/:id`} element={<CloudInstanceItemPageLazy />}>
          <Route index element={<InstanceDetailsOverviewLazy />} />
          <Route path="metrics" element={<InstanceMetricsLazy />} />
          <Route path="network_traffic" element={<InstanceNetworkTrafficLazy />} />
          <Route path="snapshots" element={<InstanceSnapshotsLazy />} />
          <Route path="backups" element={<InstanceBackupsLazy />} />
          <Route path="backup_schedules" element={<InstanceBackupsSchedulesLazy />} />
        </Route>

        <Route
          path={`${route.CLOUD_VPS}/:id/snapshots/:elid`}
          element={<CloudVpsImageDetailesPageLazy pageList="snapshots" />}
        />
        <Route
          path={`${route.CLOUD_VPS}/:id/backups/:elid`}
          element={<CloudVpsImageDetailesPageLazy pageList="backups" />}
        />

        <Route path={route.DEDICATED_SERVERS_ORDER} element={<DedicOrderPageLazy />} />
        <Route path={route.DEDICATED_SERVERS_IP} element={<DedicIPpageLazy />} />
        <Route path={route.FTP} element={<FTPPageLazy />} />
        <Route path={route.FTP_ORDER} element={<FTPOrderPageLazy />} />
        <Route path={route.DNS} element={<DNSPageLazy />} />
        <Route path={route.DNS_ORDER} element={<DNSOrderPageLazy />} />

        <Route path={route.FOREX} element={<ForexPageLazy />} />
        <Route path={route.FOREX_ORDER} element={<ForexOrderPageLazy />} />

        <Route path={`${route.FOREX}/:id`} element={<ForexItemPageLazy />}>
          <Route index element={<ForexDetailsOverviewLazy />} />
          <Route path="history" element={<ForexHistoryOverviewLazy />} />
        </Route>

        <Route path={`${route.SUPPORT}/*`} element={<SupportScreen />} />
        <Route path={`${route.BILLING}/*`} element={<BillingScreen />} />

        <Route path={route.SUCCESS_PAYMENT} element={<SuccessPayment />} />
        <Route path={route.FAILED_PAYMENT} element={<ErrorPayment />} />

        <Route path={route.USER_SETTINGS} element={<UserSettingsPageLazy />}>
          <Route path=":path/" element={<UserSettingsPageLazy />} />
        </Route>

        <Route path={route.PHONE_VERIFICATION} element={<PhoneVerificationPageLazy />} />

        <Route path={route.SOC_NET_AUTH} element={<SocialNetAddPageLazy />} />
        <Route
          path={`${route.AFFILIATE_PROGRAM}/*`}
          element={<AffiliateProgramPageLazy />}
        />

        <Route path={`${route.ERROR_PAGE}/*`} element={<ErrorPageLazy />} />

        <Route path={route.CONFIRM_EMAIL} element={<EmailConfirmation />} />
        <Route path={route.SITE_CART} element={<CartFromSite isAuth />} />
        <Route path={route.CONFIRM_MAIN_EMAIL} element={<MainEmailConfirmation />} />

        <Route path={route.PAYMENT_SAVED} element={<PaymentSavedPageLazy />} />

        <Route path={route.PAYMENT_PROCESSING} element={<PaymentProcessingPageLazy />} />

        <Route path={route.ORDER} element={<CloneOrderPageLazy />} />

        <Route path="*" element={<ErrorPageLazy />} />
      </Routes>
      {isModalCreatePaymentOpened && <ModalCreatePayment />}
      {cartState?.isOpened && (
        <Portal>
          <Cart />
        </Portal>
      )}
    </Container>
  )
}

const SupportScreen = () => {
  const location = useLocation()

  if (location.pathname === route.SUPPORT) {
    return <Navigate to={`${route.SUPPORT}/requests`} replace />
  }

  return (
    <Routes>
      <Route path=":path/*" element={<SupportPageLazy />} />
      <Route path=":path/:id" element={<OpenedTickerPageLazy />} />
    </Routes>
  )
}

const BillingScreen = () => {
  const location = useLocation()

  if (location.pathname === route.BILLING) {
    return <Navigate to={`${route.BILLING}/payments`} replace />
  }

  return (
    <Routes>
      <Route path=":path" element={<BillingPageLazy />} />
      <Route path="*" element={<ErrorPageLazy />} />
    </Routes>
  )
}

export default Component
