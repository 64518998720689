export default function SvgComponent(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="7.99951" cy="8" r="7.00146" stroke="currentColor" />
      <path d="M8 10.3337L8 5.6661" stroke="currentColor" strokeLinecap="round" />
      <path d="M10.334 8L5.66634 8" stroke="currentColor" strokeLinecap="round" />
    </svg>
  )
}
