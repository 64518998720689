import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Modal, Loader, PaginationUpdated } from '@components'
import { dedicOperations } from '@redux'

import s from './HistoryModal.module.scss'
import HistoryList from './HistoryList'

export default function HistoryModal({ elid, closeModal, name, isOpen }) {
  const dispatch = useDispatch()

  const { t } = useTranslation(['vds', 'container', 'other', 'dedicated_servers'])

  const [historyList, setHistoryList] = useState([])

  const [pagination, setPagination] = useState({})

  const getItems = ({ p_cnt, p_num }) => {
    dispatch(
      dedicOperations.getServiceHistory({
        elid,
        p_cnt,
        p_num,
        setHistoryList,
        setPagination,
      }),
    )
  }

  useEffect(() => {
    getItems({ p_num: 1, p_cnt: 10 })
  }, [])

  if (!historyList || !pagination.p_elems) {
    return <Loader />
  }

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} className={s.modal} isClickOutside>
      <Modal.Header>
        <div>
          <p className={'headings_h2'}>{t('Service change history', { ns: 'other' })}</p>

          <span className={'body_m'}>{name}</span>
        </div>
      </Modal.Header>

      <Modal.Body>{<HistoryList historyList={historyList} />}</Modal.Body>

      <Modal.Footer>
        <PaginationUpdated getItemsHandler={getItems} pagination={pagination} />
      </Modal.Footer>
    </Modal>
  )
}
