import { useTranslation } from 'react-i18next'
import { Modal } from '@components'
import s from './DomainsWhoisModal.module.scss'

export default function Component(props) {
  const { t } = useTranslation(['domains', 'other'])

  const { closeModal, whoisData, isOpen } = props

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} isClickOutside>
      <Modal.Header>
        <div>
          <p className={'headings_h2'}>{t('Domain Information')}</p>
        </div>
      </Modal.Header>
      <Modal.Body className={s.whoisText}>{whoisData}</Modal.Body>
    </Modal>
  )
}
