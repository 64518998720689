export default function SvgComponent(props) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.28546 13.8699C0.474219 15.2615 1.48874 17 3.1103 17H16.8902C18.5108 17 19.5254 15.2615 18.7151 13.8699L11.826 2.04366C11.0148 0.652113 8.98573 0.652113 8.17449 2.04366L1.28546 13.8699Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 7.5V10.625M10 13.125H10.0067V13.1317H10V13.125Z"
        stroke="currentColor"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
