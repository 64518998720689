import { useRef, useState } from 'react'
import s from './AutoRenewalTable.module.scss'
import PropTypes from 'prop-types'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { getFormatedDate, useOutsideAlerter } from '@utils'
import { TooltipWrapper, CardWrapper, InlineOptions, Options } from '@components'

export default function PaymentsTableItem(props) {
  const {
    name,
    itemtype,
    itemtype_name,
    cost,
    next_payment_date,
    full_paymethod,
    status,
    openAutoRenewModal,
    el,
  } = props
  const { t } = useTranslation(['billing', 'other', 'other', 'autoprolong'])
  const mobile = useMediaQuery({ query: '(max-width: 1023px)' })
  const widerThan1024 = useMediaQuery({ query: '(min-width: 1024px)' })
  const dropDownEl = useRef()
  const [isOpened, setIsOpened] = useState(false)

  const closeMenuHandler = () => {
    setIsOpened(!isOpened)
  }

  useOutsideAlerter(dropDownEl, isOpened, closeMenuHandler)

  const renderStatus = string => {
    const financeStatus = string?.trim()
    const financeStatusLowerCase = financeStatus?.toLowerCase()

    const status = t(string.trim(), { ns: 'other' })

    let color = '#11A63A'

    if (financeStatusLowerCase.includes('suspended')) {
      color = 'var(--system-error)'
    }

    if (financeStatusLowerCase.includes('in progress')) {
      color = 'var(--system-orange-dark)'
    }

    return {
      status,
      color,
    }
  }

  const options = [
    {
      label: t('Edit'),
      icon: 'NewIconEdit',
      onClick: () => openAutoRenewModal(),
    },
  ]

  const renderLastColumn = () => {
    return mobile ? <Options options={options} /> : <InlineOptions options={options} />
  }

  const { date: nextPaymentDate } = getFormatedDate({
    date: next_payment_date,
    time: el.next_payment_date_locale_time?.$,
  })

  return (
    <>
      {widerThan1024 ? (
        <tr>
          <td>
            <TooltipWrapper disabled={t(name).length < 10} content={t(name)}>
              <div className={cn(s.item_text, s.first_item)}>{t(name)}</div>
            </TooltipWrapper>
          </td>
          <td>{itemtype_name}</td>
          <td>{cost}</td>
          <td>{nextPaymentDate}</td>
          <td data-wrap>
            <TooltipWrapper
              disabled={t(full_paymethod, { ns: 'autoprolong' }).length < 10}
              content={t(full_paymethod, { ns: 'autoprolong' })}
              wrapperClassName={s.tooltip_wrapper}
            >
              {t(full_paymethod, { ns: 'autoprolong' })}
            </TooltipWrapper>
          </td>
          <td>
            <div style={{ color: renderStatus(status).color }}>
              {renderStatus(status).status}
            </div>
          </td>
          <td align="right">{renderLastColumn()}</td>
        </tr>
      ) : (
        <CardWrapper className={s.item}>
          <div className={s.mobileSettings}>{renderLastColumn()}</div>
          <div className={s.tableBlockFirst}>
            {mobile && (
              <div className={s.item_title}>{t('Service', { ns: 'other' })}:</div>
            )}
            <TooltipWrapper disabled={t(name).length < 10} content={t(name)}>
              <div className={cn(s.item_text, s.first_item)}>{t(name)}</div>
            </TooltipWrapper>
          </div>
          <div className={s.tableBlockSecond}>
            {mobile && (
              <div className={s.item_title}>{t('Product type', { ns: 'other' })}:</div>
            )}
            <div className={cn(s.item_text, s.second_item)}>{itemtype}</div>
          </div>
          <div className={s.tableBlockThird}>
            {mobile && <div className={s.item_title}>{t('Cost', { ns: 'other' })}:</div>}
            <div className={cn(s.item_text, s.third_item)}>{cost}</div>
          </div>
          <div className={s.tableBlockFourth}>
            {mobile && (
              <div className={s.item_title}>{t('Next payment', { ns: 'other' })}:</div>
            )}
            <div className={cn(s.item_text, s.fourth_item)}>{nextPaymentDate}</div>
          </div>
          <div className={s.tableBlockSixth}>
            {mobile && (
              <div className={s.item_title}>{t('Payment method', { ns: 'other' })}:</div>
            )}
            <TooltipWrapper
              disabled={t(full_paymethod, { ns: 'autoprolong' }).length < 10}
              content={t(full_paymethod, { ns: 'autoprolong' })}
            >
              <div className={cn(s.item_text, s.sixth_item)}>
                {t(full_paymethod, { ns: 'autoprolong' })}
              </div>
            </TooltipWrapper>
          </div>
          <div className={s.tableBlockSeventh}>
            {mobile && (
              <div className={s.item_title}>{t('status', { ns: 'other' })}:</div>
            )}
            <div
              style={{ color: renderStatus(status).color }}
              className={cn(s.item_text, s.seventh_item)}
            >
              {renderStatus(status).status}
            </div>
          </div>
        </CardWrapper>
      )}
    </>
  )
}
PaymentsTableItem.propTypes = {
  id: PropTypes.string,
  theme: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string,
  unread: PropTypes.bool,
  setSelctedTicket: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.bool]),
}

PaymentsTableItem.defaultProps = {
  id: '',
  theme: '',
  date: '',
  status: '',
  unread: false,
  setSelctedTicket: () => null,
  selected: null,
}
