/* eslint-disable no-unused-vars */
import { useEffect } from 'react'

export default function useDisableScroll({ condition, btn }) {
  useEffect(() => {
    const container =
      btn.current?.closest('[data-scroll-container]') ||
      document.getElementById('container')
    const style = window.getComputedStyle(container)

    if (condition) {
      const { clientHeight, scrollHeight } = container

      const paddingRight = +style.paddingRight.replace('px', '')
      if (clientHeight < scrollHeight) {
        container.style.overflow = 'hidden'
        container.style.paddingRight = paddingRight + 5 + 'px'
      }
    } else {
      container.style.paddingRight = ''
      container.style.overflow = ''
    }
  }, [condition])
}
