import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { Status } from '@components'
import PropTypes from 'prop-types'
import s from './ServerState.module.scss'

export default function ServerState({ className, server, onlyAutoDelete }) {
  const { t } = useTranslation('vds')

  if (onlyAutoDelete) {
    return (
      <>
        {server?.scheduledclose?.$ === 'on' && (
          <Status
            type="error"
            status="Auto delete"
            tooltipText={`${t('scheduled_deletion')}${server?.scheduledclose_prop?.$}`}
          />
        )}
      </>
    )
  }

  const statusMapping = {
    '5_open': {
      id: 'open_5',
      content: 'in_progress',
      displayStatus: 'in progress',
    },
    '5_transfer': {
      id: 'transfer_5',
      content: 'in_progress_transfer',
      displayStatus: 'transfer',
    },
    '5_close': {
      id: 'close_5',
      content: 'deletion_in_progress',
      className: s.error,
      type: 'error',
      displayStatus: 'deletion',
    },
    '3_employeesuspend': {
      id: 'employeesuspend_3',
      content: 'stopped_by_admin',
      className: s.error,
      type: 'error',
      displayStatus: 'stopped',
      attentIcon: true,
    },
    '3_autosuspend': {
      id: 'autosuspend_3',
      content: 'stopped',
      className: s.error,
      type: 'error',
      displayStatus: 'stopped',
    },
    '3_abusesuspend': {
      id: 'abusesuspend_3',
      content: 'Suspended due to abuse',
      className: s.error,
      type: 'error',
      displayStatus: 'suspended',
      attentIcon: true,
    },
  }

  const renderStatus = statusKey => {
    const status = statusMapping[statusKey]

    return (
      status && (
        <Status
          status={status?.displayStatus}
          tooltipText={t(status?.content)}
          withIcon={status?.attentIcon}
          type={status?.type}
        />
      )
    )
  }

  return (
    <div className={cn(s.wrapper, className)}>
      {server?.item_status?.$orig === '1' && <Status status="ordered" />}

      {server?.item_status?.$orig === '2' && <Status status="active" type="success" />}

      {renderStatus(server?.item_status?.$orig)}
    </div>
  )
}

ServerState.propTypes = {
  className: PropTypes.string,
  server: PropTypes.object,
}
