import s from './DeskWrapper.module.scss'
import cn from 'classnames'
import { ScrollContainer } from './ScrollContainer'
import { useMediaQuery } from 'react-responsive'
import { DeskProvider } from './DeskContext'

function DeskWrapper({
  children,
  className,
  fullHeight,
  desktopBreakpoint = 1024,
  ...props
}) {
  const isDesktop = useMediaQuery({ query: `(min-width: ${desktopBreakpoint}px)` })

  return (
    <DeskProvider value={{ isDesktop }}>
      <div
        className={cn(s.desk, className, {
          [s.full_height]: fullHeight,
          [s.desktop]: isDesktop,
        })}
        {...props}
      >
        {children}
      </div>
    </DeskProvider>
  )
}

DeskWrapper.ScrollContainer = ScrollContainer

export default DeskWrapper
