import { useEffect, useRef, useState } from 'react'
import s from './InstancesList.module.scss'
import cn from 'classnames'
import {
  AutoprolongIcon,
  CopyText,
  EditCell,
  IconButton,
  InstancesOptions,
  CardWrapper,
  InstanceStatus,
} from '@components'
import * as route from '@src/routes'
import { useNavigate } from 'react-router-dom'
import {
  getFlagFromCountryName,
  getInstanceMainInfo,
  formatCountryName,
  cutDcSuffix,
  getImageIconName,
  getItemCostValue,
  getFormatedDate,
} from '@utils'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { selectors } from '@redux'

export default function InstanceItemMobile({ item, editInstance }) {
  const { t } = useTranslation(['cloud_vps', 'countries', 'other'])
  const header = useRef()
  const navigate = useNavigate()
  const darkTheme = useSelector(selectors.getTheme) === 'dark'

  const { isNotActive } = getInstanceMainInfo(item)

  const [serverName, setServerName] = useState(item.servername?.$ || '')
  const itemCountry = formatCountryName(item, t)
  const ipv4 = item?.ip?.$
  const ipv6 = item?.ip_v6?.$

  const osIcon = getImageIconName(item?.os_distro?.$, darkTheme)

  const tooltipContent = () => {
    if (!item?.os_distro?.$ && !item?.os_version?.$) {
      return t('select.detecting', { ns: 'filter' })
    }

    const osDistro = item?.os_distro?.$ ? item.os_distro.$ : ''
    const osVersion = item?.os_version?.$ ? item.os_version.$ : ''

    return `${osDistro} ${osVersion}`.trim()
  }

  const editServerName = value => {
    const slicedValue = value.slice(0, 100)
    editInstance({
      value: slicedValue,
      elid: item.id.$,
      errorCallback: () => setServerName(serverName),
    })
    setServerName(value)
  }

  useEffect(() => {
    setServerName(item.servername?.$ || '')
  }, [item.servername?.$])

  const openInsatncePage = () => {
    navigate(`${route.CLOUD_VPS}/${item.id.$}`, { state: item })
  }

  const { date: expiredate, time: expiretime } = getFormatedDate({
    date: item.real_expiredate?.$,
    time: item.i_expiretime?.$,
  })
  const { date: createdate, time: createtime } = getFormatedDate({
    date: item.createdate?.$,
    time: item.i_opentime?.$,
  })

  return (
    <CardWrapper>
      <div className={s.mobile_item__header} ref={header}>
        <div className={s.mobile_item__header_name}>
          <div className={s.mobile_item__name}>
            <EditCell
              originName={serverName}
              onSubmit={editServerName}
              placeholder={t(serverName || t('server_placeholder', { ns: 'vds' }), {
                ns: 'vds',
              })}
            />
          </div>
          <span className={cn('text-dark-50', 'body_s')}>ID: {item.id.$}</span>
        </div>
        <div className={s.mobile_item__header_actions}>
          {!isNotActive && (
            <IconButton
              color="third"
              size="small"
              icon="NewIconArrowUpRight"
              onClick={openInsatncePage}
              data-stop-propagation
            />
          )}
          <InstancesOptions item={item} isMobile />
        </div>
      </div>
      <CardWrapper.Grid className={s.mobile_item__body}>
        <div>{t('Status')}</div>
        <div>
          <InstanceStatus item={item} />
        </div>
        <div>{t('pricing_plan')}</div>
        <div>{cutDcSuffix(item.pricelist.$)}</div>

        <div>{t('Price')}</div>
        <div>{getItemCostValue(item.cost.$, t)}</div>

        <div>{t('data_center')}</div>
        <div className={cn(s.mobile_item__value, s.withIcon)}>
          {item?.datacentername && (
            <>
              <img
                src={require(`@images/countryFlags/${getFlagFromCountryName(
                  itemCountry?.countryName,
                )}.png`)}
                width={20}
                height={20}
                alt={itemCountry?.countryName}
              />
              <span>{itemCountry?.DCName}</span>
            </>
          )}
        </div>

        <div>{t('Created at')}</div>
        <div>
          <p className={s.row}>
            {item.createdate?.$ && item.i_opentime?.$ ? (
              <>
                {createdate} <span className="text-dark-50">{createtime}</span>
              </>
            ) : (
              '-'
            )}
          </p>
        </div>
        <div>{t('paid_until')}</div>
        <div>
          <div className={s.row}>
            {expiredate}
            <div className={s.row}>
              <span className="text-dark-50">{expiretime}</span>
              <AutoprolongIcon item={item} />
            </div>
          </div>
        </div>
        {osIcon && (
          <>
            <div>{t('OS')}</div>
            <div className={cn(s.mobile_item__value, s.withIcon)}>
              <img
                src={require(`@images/soft_os_icons/${osIcon}.png`)}
                alt={item?.os_distro?.$}
              />
              <span>{tooltipContent()}</span>
            </div>
          </>
        )}

        <div>{t('Access IP')}</div>
        <div>
          {ipv4 || ipv6 ? (
            <div className={s.row}>
              {ipv4 && (
                <CopyText
                  text={ipv4}
                  promptTextOnHover={ipv4}
                  promptText={t('ip_address_copied')}
                  label="IPv4"
                />
              )}
              {ipv6 && (
                <CopyText
                  text={ipv6}
                  promptTextOnHover={ipv6}
                  promptText={t('ip_address_copied')}
                  label="IPv6"
                />
              )}
            </div>
          ) : (
            '-'
          )}
        </div>
      </CardWrapper.Grid>
    </CardWrapper>
  )
}
