import PropTypes from 'prop-types'
import { Modal } from '@components'

import s from './Alert.module.scss'

export default function Alert({
  isOpened,
  title,
  mainBtn,
  text,
  controlAlert,
  datatestid,
}) {
  return (
    <Modal
      isOpen={isOpened}
      closeModal={controlAlert}
      data-testid={datatestid}
      className={s.modal}
    >
      <Modal.Header>
        <div>
          <p className={'headings_h2'}>{title}</p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <p className={'body_m_l'}>{text}</p>
      </Modal.Body>
      <Modal.Footer column>{mainBtn}</Modal.Footer>
    </Modal>
  )
}

Alert.propTypes = {
  isOpened: PropTypes.bool,
  title: PropTypes.string,
  mainBtn: PropTypes.object,
  text: PropTypes.string,
  controlAlert: PropTypes.func,
  datatestid: PropTypes.string,
}
