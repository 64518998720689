import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import s from './DedicIPEditModal.module.scss'

import { Button, InputField, Modal, Cta } from '@components'
import { useLocation } from 'react-router-dom'
import { dedicOperations } from '@redux'

export default function DedicIPEditModal({ elid, closeFn, plid }) {
  const { t } = useTranslation(['dedicated_servers', 'vds', 'other'])
  const dispatch = useDispatch()
  const [initialState, setInitialState] = useState()

  const location = useLocation()
  const ipPlid = location?.state?.plid || plid

  const handleEditionModal = () => {
    closeFn()
  }

  useEffect(() => {
    dispatch(dedicOperations.getInfoEditIP(elid, ipPlid, setInitialState))
  }, [])

  const handleSubmit = values => {
    const { mask, gateway, domainname } = values

    dispatch(
      dedicOperations.editIP(elid, ipPlid, mask, gateway, domainname, handleEditionModal),
    )
  }

  const validationSchema = Yup.object().shape({
    domainname: Yup.string().matches(
      /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/,
      t('licence_error'),
    ),
  })

  return (
    <Modal closeModal={closeFn} isOpen className={s.modal}>
      <Modal.Header>
        <div>
          <p className={'headings_h2'}>{t('Editing a service', { ns: 'other' })}</p>

          <span className={'body_m'}>{initialState?.domain_name?.$}</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{
            domainname: initialState?.domain?.$ || '',
            mask: initialState?.mask?.$ || '',
            gateway: initialState?.gateway?.$ || '',
          }}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched }) => {
            return (
              <Form id="edit-ip">
                <div className={s.body}>
                  <InputField
                    label={t('domain_name')}
                    placeholder={t('domain_placeholder')}
                    name="domainname"
                    error={!!errors.domainname}
                    touched={!!touched.domainname}
                    autoComplete
                    type="text"
                    value={values?.domainname}
                  />

                  <InputField
                    label={`${t('mask')}:`}
                    name="mask"
                    autoComplete="off"
                    type="text"
                    value={values?.mask}
                    disabled
                  />

                  <InputField
                    label={`${t('gateway')}:`}
                    name="gateway"
                    autoComplete="off"
                    type="text"
                    value={values?.gateway}
                    disabled
                  />
                </div>
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className={s.submit_btn}
          size="medium"
          label={t('Save', { ns: 'other' })}
          type="submit"
          form="edit-ip"
        />
        <Cta buttonType="button" view="secondary" onClick={closeFn} className={'body_m'}>
          {t('Cancel', { ns: 'other' })}
        </Cta>
      </Modal.Footer>
    </Modal>
  )
}
