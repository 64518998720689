import cn from 'classnames'
import s from './AccessRightsModal.module.scss'
import { Button, Icon, Modal } from '@components'
import { useDispatch } from 'react-redux'
import { usersActions } from '@redux'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import { useMemo, useState } from 'react'
import List from './List'

export default function AccessRightsModal({ isOpen, items, closeModal, userId }) {
  const dispatch = useDispatch()
  const mobile = useMediaQuery({ query: '(max-width: 767px)' })
  const { t } = useTranslation('trusted_users')

  const [isRightsChanged, setIsRightsChanges] = useState(false)

  const closeModalHandler = () => {
    closeModal()
    dispatch(usersActions.setRights([]))
  }
  const [openedCategory, setOpenedCategory] = useState('')

  const groupedItems = useMemo(() => {
    let category
    return items?.reduce(
      (res, item) => {
        if (!item.active) {
          category = item.name.$
          res.categories.push(item.name.$)
          res[category] = { ...item, subItems: [] }
        } else {
          res[category].subItems.push(item)
        }

        return res
      },
      { categories: [] },
    )
  }, [items])

  const renderIcons = icon => {
    switch (icon) {
      case 'customer':
        return 'Client'
      case 'mainmenuservice':
        return 'Services'
      case 'finance':
        return 'Finance'
      case 'support':
        return 'Help'
      case 'mainmenutool':
        return 'Instruments'
      case 'stat':
        return 'Statistic'
      case 'mgrhelp':
        return 'Reference'
      case 'dashboard':
        return 'Home'
      case 'reselling':
        return 'Money'
      case 'cloud':
        return 'Cloud'
      default:
        return null
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModalHandler}
      className={cn({ [s.hidden]: !items.length })}
    >
      <Modal.Header>
        <div>
          <p className={'headings_h2'}>
            {mobile
              ? t('trusted_users.rights_alert.title_short')
              : t('trusted_users.rights_alert.title_long')}
          </p>
        </div>
      </Modal.Header>
      <Modal.Body>
        <ul className={s.list}>
          {groupedItems.categories.map(groupItem => {
            const item = groupedItems[groupItem]
            const itemName = item.name?.$
            const isOpened = openedCategory === itemName

            return (
              <li
                key={groupItem}
                className={cn(s.item_wrapper, { [s.opened]: isOpened })}
              >
                <button
                  className={cn(s.item, s.group_head, s.item_clickable)}
                  onClick={() => setOpenedCategory(isOpened ? '' : itemName)}
                >
                  <Icon name={renderIcons(item.name.$)} className={s.group_icon} />
                  <span className={cn(s.item_name)}>
                    {t(`trusted_users.rights_alert.${itemName.replaceAll('.', '_')}`)}
                  </span>
                  <Icon name="Shevron" className={cn(s.shevron)} />
                </button>
                {isOpened && (
                  <List
                    items={item.subItems}
                    userId={userId}
                    nested={1}
                    isRightsChanged={isRightsChanged}
                    setIsRightsChanges={setIsRightsChanges}
                  />
                )}
              </li>
            )
          })}
        </ul>
      </Modal.Body>
      <Modal.Footer column>
        <Button onClick={closeModal} label="OK" className={s.submit__btn} />
      </Modal.Footer>
    </Modal>
  )
}
