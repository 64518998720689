import { Icon, TooltipWrapper } from '@components/index'
import s from './Status.module.scss'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'

export default function Status({
  status,
  tooltipText,
  withIcon,
  icon = 'Attention',
  className,
  type = 'warn',
  bigSize,
}) {
  const { t } = useTranslation('vds')

  return (
    <TooltipWrapper content={tooltipText || t(status)} className={s.popup}>
      <span
        className={cn(s.status, s[type], className, {
          [s.with_icon]: withIcon,
          [s.status_big]: bigSize,
        })}
      >
        {status}
        {withIcon && <Icon name={icon} className={s[type]} />}
      </span>
    </TooltipWrapper>
  )
}
