import PropTypes from 'prop-types'
import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import {
  CheckBox,
  ServerState,
  Options,
  CardWrapper,
  AutoprolongIcon,
  TooltipWrapper,
  IconButton,
} from '@components'
import s from './SharedHostingTable.module.scss'
import {
  formatCountryName,
  getFlagFromCountryName,
  getFormatedDate,
  getItemCostValue,
  isUnpaidOrder,
  useCreateTicketOption,
  useOpenInNewWindow,
} from '@src/utils'
import * as route from '@src/routes'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'

export default function SharedHostingTableItem(props) {
  const {
    id,
    domain,
    tariff,
    cost,
    setSelctedItem,
    // selected,
    el,
    historyVhostHandler,
    instructionVhostHandler,
    platformVhostHandler,
    prolongVhostHandler,
    editVhostHandler,
    changeTariffVhostHandler,
    setElForProlongModal,
    ip,
    datacentername,
    rights,
    activeServices,
    setActiveServices,
    setIdForDeleteModal,
    unpaidItems,
  } = props
  const { t } = useTranslation([
    'domains',
    'other',
    'vds',
    'dedicated_servers',
    'countries',
  ])
  const mobile = useMediaQuery({ query: '(max-width: 1599px)' })
  const isDesktop = useMediaQuery({ query: '(min-width: 1024px)' })

  const navigate = useNavigate()
  const handleNewWindowNavigate = useOpenInNewWindow()

  const optionsCell = useRef()

  const checkboxCell = useRef()

  const isActive = activeServices?.some(service => service?.id?.$ === id)
  const toggleIsActiveHandler = () => {
    isActive
      ? setActiveServices(activeServices?.filter(item => item?.id?.$ !== id))
      : setActiveServices([...activeServices, el])
  }

  const unpaidOption = isUnpaidOrder(el, unpaidItems)
  const createTicketOption = useCreateTicketOption(id)

  const options = [
    unpaidOption,
    {
      label: t('instruction', { ns: 'vds' }),
      icon: 'Instruction',
      disabled: !rights?.instruction || el?.status?.$ === '1',
      onClick: instructionVhostHandler,
    },
    {
      label: t('go_to_panel', { ns: 'vds' }),
      icon: 'ToPanel',
      disabled: el.transition?.$ !== 'on' || el?.status?.$ !== '2' || !rights?.gotoserver,
      onClick: platformVhostHandler,
    },
    {
      label: t('prolong', { ns: 'vds' }),
      icon: 'Renew',
      disabled: !rights?.prolong || el?.status?.$ === '1' || el?.status?.$ === '5',
      onClick: () => {
        prolongVhostHandler()
        setElForProlongModal([el])
      },
    },
    {
      label: t('edit', { ns: 'other' }),
      icon: 'NewIconEdit',
      disabled: el?.status?.$ === '5' || !rights?.edit,
      onClick: () => editVhostHandler(),
    },
    {
      label: t('trusted_users.Change tariff', { ns: 'trusted_users' }),
      icon: 'Resize',
      disabled:
        !rights?.changepricelist ||
        el?.status?.$ === '1' ||
        el?.status?.$ === '5' ||
        el?.change_pricelist.$ === 'off',
      onClick: changeTariffVhostHandler,
    },
    {
      label: t('history', { ns: 'vds' }),
      icon: 'HistoryTimer',
      disabled: !rights?.history,
      onClick: historyVhostHandler,
    },
    createTicketOption,
    {
      label: t('delete', { ns: 'other' }),
      icon: 'Delete',
      disabled:
        el?.status?.$ === '5' || el?.scheduledclose?.$ === 'on' || !rights?.delete,
      onClick: () => setIdForDeleteModal([id]),
      isDelete: true,
    },
  ]

  const { date: expiredate } = getFormatedDate({
    date: el.real_expiredate?.$,
    time: el.i_expiretime?.$,
  })
  const { date: createdate } = getFormatedDate({
    date: el.createdate?.$,
    time: el.i_opentime?.$,
  })

  const itemCountry = formatCountryName(el, t)

  return (
    <>
      {isDesktop ? (
        <tr
          onClick={e => {
            setSelctedItem(id)

            if (
              optionsCell?.current?.contains(e?.target) ||
              checkboxCell?.current?.contains(e?.target)
            ) {
              return
            }

            handleNewWindowNavigate(e, `${route.SHARED_HOSTING}/${id}`, {
              state: el,
            })
          }}
          data-stop-propagation
          tabIndex={0}
        >
          <td ref={checkboxCell} data-target="checkbox">
            <CheckBox value={isActive} onClick={toggleIsActiveHandler} />
          </td>
          <td>
            <div data-td-value-top>
              {domain ? domain : t('Not provided', { ns: 'dedicated_servers' })}
            </div>
            <span className={cn('text-dark-50', 'body_s', s.item_id)}>ID: {el.id.$}</span>
          </td>
          <td>
            <div data-td-value-top>
              {ip ? ip : t('Not provided', { ns: 'dedicated_servers' })}
            </div>
          </td>
          <td>
            <div data-td-value-top data-wrap>
              {tariff}
            </div>
            {getItemCostValue(cost, t)}
          </td>
          <td>
            <ServerState server={el} />
          </td>
          <td>
            {datacentername ? (
              <TooltipWrapper className={s.popup} content={itemCountry?.DCName}>
                <img
                  src={require(`@images/countryFlags/${getFlagFromCountryName(
                    itemCountry?.countryName,
                  )}.png`)}
                  width={20}
                  height={20}
                  alt={itemCountry?.countryName}
                />
              </TooltipWrapper>
            ) : (
              '-'
            )}
          </td>

          <td>{createdate}</td>
          <td>
            <div className="in_row align_start" data-td-value-top>
              {expiredate} <AutoprolongIcon item={el} />
            </div>
            <ServerState server={el} onlyAutoDelete />
          </td>

          <td ref={optionsCell}>
            <Options
              options={options}
              useModalOnMobile
              columns={2}
              dropdownClassName={s.options_dopdown}
            />
          </td>
        </tr>
      ) : (
        <CardWrapper className={s.item_container} onClick={() => setSelctedItem(id)}>
          <div
            data-testid="archive_item"
            role="button"
            tabIndex={0}
            onKeyDown={() => {}}
            onClick={() => setSelctedItem(id)}
            // className={cn(s.item, { [s.selected]: false })}
          >
            {mobile && <CheckBox value={isActive} onClick={toggleIsActiveHandler} />}

            {mobile && <div className={s.line} />}

            <div className={s.tableBlockFirst}>
              {mobile && <div className={s.item_title}>{t('Id')}:</div>}
              <div className={cn(s.item_text, s.first_item)}>{id}</div>
            </div>
            <div className={s.tableBlockSecond}>
              {mobile && <div className={s.item_title}>{t('Domain name')}:</div>}
              <div className={cn(s.item_text, s.second_item, { [s.inactive]: !domain })}>
                {domain ? domain : t('Not provided', { ns: 'dedicated_servers' })}
              </div>
            </div>
            <div className={s.tableBlockThird}>
              {mobile && <div className={s.item_title}>{t('IP address')}:</div>}
              <div className={cn(s.item_text, s.second_item, { [s.inactive]: !ip })}>
                {ip ? ip : t('Not provided', { ns: 'dedicated_servers' })}
              </div>
            </div>
            <div className={s.tableBlockFourth}>
              {mobile && <div className={s.item_title}>{t('pricing_plan')}:</div>}
              <div className={cn(s.item_text, s.third_item)}>{tariff}</div>
            </div>
            <div className={s.tableBlockSeventh}>
              {mobile && (
                <div className={s.item_title}>{t('status', { ns: 'other' })}:</div>
              )}
              <ServerState server={el} />
            </div>
            <div className={s.tableBlockFifth}>
              {mobile && <div className={s.item_title}>{t('data_center')}:</div>}
              <div
                className={cn(s.item_text, s.third_item, {
                  [s.inactive]: !datacentername,
                })}
              >
                {datacentername ? (
                  <TooltipWrapper className={s.popup} content={itemCountry?.DCName}>
                    <img
                      src={require(`@images/countryFlags/${getFlagFromCountryName(
                        itemCountry?.countryName,
                      )}.png`)}
                      width={20}
                      height={20}
                      alt={itemCountry?.countryName}
                    />
                  </TooltipWrapper>
                ) : (
                  '-'
                )}
              </div>
            </div>
            <div className={s.tableBlockSixth}>
              {mobile && <div className={s.item_title}>{t('created_at')}:</div>}
              <div className={cn(s.item_text, s.fourth_item)}>{createdate}</div>
            </div>
            <div className={s.tableBlockSixth}>
              {mobile && <div className={s.item_title}>{t('valid_until')}:</div>}
              <div className={cn(s.item_text, s.fourth_item)}>
                <div className="in_row">
                  {expiredate} <AutoprolongIcon item={el} />
                </div>
                <ServerState className={s.value} server={el} onlyAutoDelete />
              </div>
            </div>

            <div className={s.tableBlockEighth}>
              {mobile && <div className={s.item_title}>{t('Price')}:</div>}
              <div className={cn(s.item_text, s.seventh_item)}>
                {cost.replace('Month', t('Month', { ns: 'other' }))}
              </div>
            </div>
            <div className={s.dots}>
              <div className="in_row">
                <IconButton
                  color="third"
                  size="small"
                  icon="NewIconArrowUpRight"
                  onClick={() => {
                    navigate(`${route.SHARED_HOSTING}/${id}`, {
                      state: el,
                    })
                  }}
                  data-stop-propagation
                />
                <Options options={options} useModalOnMobile />
              </div>
            </div>
          </div>
        </CardWrapper>
      )}
    </>
  )
}
SharedHostingTableItem.propTypes = {
  id: PropTypes.string,
  theme: PropTypes.string,
  date: PropTypes.string,
  status: PropTypes.string,
  unread: PropTypes.bool,
  setSelctedTicket: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.oneOf([null]), PropTypes.bool]),
  rights: PropTypes.object,
  activeServices: PropTypes.array,
  setActiveServices: PropTypes.func,
  setElForProlongModal: PropTypes.func,
}

SharedHostingTableItem.defaultProps = {
  id: '',
  theme: '',
  date: '',
  status: '',
  unread: false,
  setSelctedTicket: () => null,
  selected: null,
}
