import { CLOUDS_ACTIONS } from '@utils/constants'
import { BootFromIsoModal, RebuildModal, RescueModal } from '.'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { cloudVpsOperations } from '@redux'

export const RenderBuildModal = props => {
  const { item, closeModal } = props

  const dispatch = useDispatch()

  const [data, setData] = useState()
  const [operationSystems, setOperationSystems] = useState([])

  const zoneList = useMemo(() => {
    let list = []

    list = list.concat(data?.slist.find(el => el.$name === 'zone')?.val.map(({ $ }) => $))

    return list
  }, [data])

  useEffect(() => {
    dispatch(
      cloudVpsOperations.rebuildInstance({
        action: item.rebuild_action,
        elid: item.id.$,
        setOperationSystems,
        successCallback: value => {
          setData(value)
        },
        errorCallback: closeModal,
      }),
    )
  }, [])

  const renderModal = cloudAction => {
    switch (cloudAction) {
      case CLOUDS_ACTIONS.rebuild:
        return (
          <RebuildModal
            {...props}
            data={data}
            operationSystems={operationSystems}
            zoneList={zoneList}
          />
        )
      case CLOUDS_ACTIONS.boot_from_iso:
        return (
          <BootFromIsoModal
            {...props}
            data={data}
            operationSystems={operationSystems}
            zoneList={zoneList}
          />
        )
      case CLOUDS_ACTIONS.rescue:
        return (
          <RescueModal
            {...props}
            data={data}
            operationSystems={operationSystems}
            zoneList={zoneList}
          />
        )
      default:
        return null
    }
  }

  return renderModal(item?.rebuild_action)
}
