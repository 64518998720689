import {
  Button,
  InputField,
  Modal,
  MessageInput,
  NotificationMessage,
  CopyText,
  ScrollToFieldError,
  IconButton,
  Cta,
} from '@components'
import { SSH_KEY_NAME_REGEX, CYRILLIC_ALPHABET_PROHIBITED } from '@utils/constants'

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cloudVpsActions, cloudVpsOperations, cloudVpsSelectors } from '@redux'

import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import s from './Modals.module.scss'
import cn from 'classnames'

export const SshKeyModal = ({ item, closeModal, onSubmit }) => {
  const [allSshItems, setAllSshItems] = useState([])
  const [privateKey, setPrivateKey] = useState('')
  const { t } = useTranslation('cloud_vps', 'other', 'user_settings', 'auth')
  const dispatch = useDispatch()

  const mode = typeof item === 'object' ? 'edit' : 'add'

  const allSshCount = useSelector(cloudVpsSelectors.getSshCount)

  /* Dispatching all user ssh keys to check it names before sending request */
  useEffect(() => {
    dispatch(
      cloudVpsOperations.getSshKeys({
        p_cnt: allSshCount,
        setAllSshItems,
      }),
    )
  }, [])

  const setNewSshKey = values => {
    dispatch(
      cloudVpsOperations.editSsh({
        ...values,
        p_cnt: allSshCount + 1,
        setAllSshItems: list => dispatch(cloudVpsActions.setAllSshList(list)),
        closeModal: () =>
          dispatch(cloudVpsActions.setItemForModals({ ssh_rename: false })),
      }),
    )
  }

  const validationSchema = Yup.object().shape({
    comment: Yup.string()
      .required(t('Is a required field', { ns: 'other' }))
      .test('trim', t('Name cannot consist spaces'), value => {
        if (value) {
          const trimmedValue = value.trim()
          return trimmedValue.length > 0
        }

        return true
      })
      .max(25, t('Name can have no more than'))
      .matches(SSH_KEY_NAME_REGEX, t('Name can only contain'))
      .test('unique', t('This name is already in use'), value => {
        if (mode === 'add') {
          return !allSshItems.some(item => item.comment.$ === value)
        }

        if (mode === 'edit') {
          const id = item?.elid?.$
          const editedItem = allSshItems.find(item => item.elid.$ === id)
          return (
            editedItem.comment.$ === value ||
            !allSshItems.some(item => item.comment.$ === value)
          )
        }
        return true
      }),
    publicKey: Yup.string()
      .required(t('Is a required field', { ns: 'other' }))
      .matches(
        CYRILLIC_ALPHABET_PROHIBITED,
        t('warnings.cyrillic_prohibited', { ns: 'auth' }),
      ),
  })

  const donwloadSSHHandler = () => {
    const link = document.createElement('a')
    const url = window.URL.createObjectURL(new Blob([privateKey], { type: 'text/plain' }))
    link.setAttribute('href', url)
    link.setAttribute('download', 'rsa.pem')
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(url)
    document.body.removeChild(link)
  }

  return (
    <Modal isOpen={!!item} closeModal={closeModal}>
      <Modal.Header>
        <div>
          <p className={'headings_h2'}>
            {mode === 'edit' ? t('Rename') : t('Add new SSH Key')}
          </p>

          {mode === 'edit' && <span className={'body_m'}>{item?.comment?.$}</span>}
        </div>
      </Modal.Header>

      <Modal.Body>
        <Formik
          initialValues={{
            comment: item?.comment?.$ || '',
            publicKey: item?.publicKey?.$ || '',
            privateKey: '',
          }}
          validationSchema={validationSchema}
          onSubmit={values => {
            const comment = values.comment.trim()
            const publicKey = values.publicKey.trim()

            onSubmit
              ? onSubmit({ values: { comment, publicKey }, closeModal })
              : setNewSshKey({ values: { comment, publicKey } })
          }}
        >
          {({ values, errors, touched, setFieldValue }) => {
            const setSSHKey = ({ publicKey, privateKey }) => {
              setFieldValue('publicKey', publicKey)
              setFieldValue('privateKey', privateKey)
              setPrivateKey(privateKey)
            }

            const generateSshHandler = () =>
              dispatch(
                cloudVpsOperations.generateSsh({
                  setSSHKey,
                }),
              )

            return (
              <Form id={'add_ssh'} className={cn(s.form, s.sshForm, s.body)}>
                <ScrollToFieldError />
                <p>{t('add_ssh_message')}</p>
                <InputField
                  inputClassName={s.input}
                  name="comment"
                  label={`${t('Name')}:`}
                  placeholder={t('Enter name', { ns: 'other' })}
                  error={!!errors.comment}
                  touched={!!touched.comment}
                  isRequired
                  autoComplete="off"
                />

                {mode === 'add' && (
                  <Button
                    className={s.sshGenerateBtn}
                    type="button"
                    onClick={generateSshHandler}
                    label={t('generate_new_key')}
                    size="large"
                  />
                )}

                <MessageInput
                  message={values?.publicKey}
                  enableFiles={false}
                  name={'publicKey'}
                  textareaClassName={s.sshAreaInput}
                  placeholderText={t('Enter your SSH key')}
                  label={`${t('ssh_key')}:`}
                  isRequired
                />

                {privateKey && (
                  <div className={s.privateKey_block}>
                    <NotificationMessage type="warning">
                      {t('private_warn_message')}
                    </NotificationMessage>

                    <div className={s.privateKeyWrapper}>
                      <MessageInput
                        message={values?.privateKey}
                        enableFiles={false}
                        name={'privateKey'}
                        textareaClassName={s.sshAreaInput}
                        placeholderText={t('Enter your SSH key')}
                        label={`${t('private_ssh_key')}:`}
                        disabled
                        labelAdditionalComponent={
                          <CopyText
                            text={values.privateKey}
                            className={s.copyKeyBtn}
                            promptText={t('key_copied')}
                          />
                        }
                      />
                    </div>
                  </div>
                )}
              </Form>
            )
          }}
        </Formik>
      </Modal.Body>

      <Modal.Footer className={s.ssh_footer}>
        <Button
          label={t('Save key', { ns: 'user_settings' })}
          size="large"
          type="submit"
          form="add_ssh"
          className={s.ssh_submit_btn}
        />
        <Cta
          buttonType="button"
          view="secondary"
          onClick={closeModal}
          className={cn('body_m', s.ssh_cancel_btn)}
        >
          {t('Cancel', { ns: 'other' })}
        </Cta>

        {privateKey && (
          <IconButton
            icon="Download"
            onClick={donwloadSSHHandler}
            className={s.ssh_download_btn}
          />
        )}
      </Modal.Footer>
    </Modal>
  )
}
