import { axiosInstance } from '@config/axiosInstance'

/**
 * Long request handling if we got html markup as string
 */
export default async function handleLongRequest(data, errorHandler) {
  if (typeof data === 'string') {
    const longUrl = data.match(/long.+billmgr/)?.[0]

    try {
      if (longUrl) {
        const response = await axiosInstance.get(longUrl).finally(response => {
          return response
        })

        const responseData = response.data

        if (responseData) {
          return await handleLongRequest(responseData)
        } else {
          throw new Error('No data received from the server')
        }
      }
    } catch (error) {
      errorHandler(error)
    }
  } else {
    return data
  }
}
