import cn from 'classnames'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import PropTypes from 'prop-types'
import { Modal, Table } from '@components'

import s from './DetailsModal.module.scss'

export default function DetailsModal({ details, closeModal }) {
  const { t } = useTranslation(['affiliate_program', 'other'])
  const widerThanMobile = useMediaQuery({ query: '(min-width: 768px)' })
  let daySum = 0

  let sortedItems

  sortedItems = details?.sort((a, b) => {
    return b?.reward?.$?.replace('%', '') - a?.reward?.$?.replace('%', '')
  })

  return (
    <Modal
      closeModal={closeModal}
      isOpen={details.length > 0}
      className={cn(s.modal, s.details_modal)}
      isClickOutside
    >
      <Modal.Header>
        <div>
          <p className={'headings_h2'}>{t('income_section.detailed_statistics')}</p>

          <span className={'body_m'}>{details[0]?.cdate?.$}</span>
        </div>
      </Modal.Header>
      <Modal.Body>
        <>
          {widerThanMobile ? (
            <Table color="secondary">
              <thead>
                <tr>
                  <th>{t('income_section.service')}</th>
                  {/* <th>{t('income_section.rate')}</th> */}
                  <th>{t('income_section.income')}</th>
                  <th>{t('income_section.referral')}</th>
                </tr>
              </thead>
              <tbody>
                {sortedItems.map(({ amount, name, referal }, index) => {
                  daySum += Number(amount.$.replace(' EUR', ''))
                  return (
                    <tr key={index}>
                      <td>{t(`services.${name.$.trim()}`, { ns: 'other' })}</td>

                      {/* <td>{reward.$}</td> */}

                      <td>{amount.$}</td>

                      <td>{referal.$}</td>
                    </tr>
                  )
                })}
              </tbody>
            </Table>
          ) : (
            <ul className={s.list}>
              {sortedItems.map(({ amount, name, referal, reward }, index) => {
                daySum += Number(amount.$.replace(' EUR', ''))

                return (
                  <li className={s.list_item} key={index}>
                    <span className={s.label}>{t('income_section.service')}:</span>
                    <span>{t(`services.${name.$.trim()}`, { ns: 'other' })}</span>

                    <span className={s.label}>{t('income_section.rate')}:</span>
                    <span>{reward.$}</span>

                    <span className={s.label}>{t('income_section.income')}:</span>
                    <span>{amount.$}</span>

                    <span className={s.label}>{t('income_section.referral')}:</span>
                    <span>{referal.$}</span>
                  </li>
                )
              })}
            </ul>
          )}
        </>
      </Modal.Body>
      <Modal.Footer>
        <p className={s.total}>
          <span className={s.total_label}>{t('income_section.total')}:</span>{' '}
          {daySum.toFixed(2)} EUR
        </p>
      </Modal.Footer>
    </Modal>
  )
}

DetailsModal.propTypes = {
  details: PropTypes.array,
  closeModal: PropTypes.func,
}
