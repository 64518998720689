export default function getItemCostValue(cost, t, replaceEur) {
  const translated = cost
    .replace('Day', t('day', { ns: 'other' }))
    .replace('Month', t('Month', { ns: 'other' }))
    .replace('Year', t('Year', { ns: 'other' }))
  const splitted = translated.split('/')

  const text1 = replaceEur ? '€ ' + splitted?.[0]?.replace('EUR', '') : splitted?.[0]

  let text2 = splitted?.[1].replace(
    'Late renewal fee',
    t('Late renewal fee', { ns: 'other' }),
  )

  const match = text2.match(/\s{2}(\d+(\.\d+)?)[\s]*EUR/)

  if (match) {
    // Get the number and round it to two decimal places
    const formattedPrice = `€ ${parseFloat(match[1]).toFixed(2)}`

    // Replace the old number format with “EUR” with the new one with the € symbol
    text2 = text2.replace(/\s{2}\d+(\.\d+)?[\s]*EUR/, `  ${formattedPrice}`)
  }

  return (
    <>
      {text1} / <span className="text-dark-50 body_m">{text2}</span>
    </>
  )
}
