import { authOperations, authActions } from '@redux'
import cookies from './cookies'
import { t, exists as isTranslationExists } from 'i18next'
import { toast } from 'react-toastify'
import { ERROR_ALREADY_HANDLED } from './constants'

const userRightsRegex =
  /Changing rights of the user (\d+) is restricted\. This user has the full access to all functions/

const bankCardsMirRegex =
  /You are trying to access the 'Payment confirmation' step that is currently not available/

const purseCurrencyRegex =
  /"__purse_currency__" currency code must match the payment currency/

const errorHandlers = [
  {
    condition: text =>
      text.includes('Request in process, please wait') ||
      text.includes('Ваш запрос обрабатывается, пожалуйста, подождите'),
    action: () => {
      toast.error(`${t('long_request', { ns: 'other' })}`)
    },
  },
  {
    condition: text =>
      text.includes('У вас недостаточно прав на выполнение функции') ||
      text.includes('Insufficient privileges to perform'),
    action: ({ dispatch }) => {
      dispatch(authOperations.getCurrentSessionStatus())
    },
  },
  {
    condition: text => text.includes('Access from this IP denied'),
    action: ({ dispatch }) => {
      dispatch(authActions.setAuthErrorMsg('warnings.badip'))
      cookies.eraseCookie('sessionId')
      dispatch(authActions.logoutSuccess())
    },
  },
  {
    condition: text => text === 'clone_error',
    action: () => {
      toast.error(`${t('clone_error', { ns: 'cart' })}`)
    },
  },
  {
    condition: text =>
      text.match(bankCardsMirRegex) ||
      text.match(userRightsRegex) ||
      text.match(purseCurrencyRegex),
    action: () => {
      console.log('This is an expected error.')
    },
  },
  {
    condition: text =>
      text.match(/Service renewal is waiting for payment of invoice '([^']+)'/),
    action: ({ match }) => {
      toast.error(t('service_payment_required', { invoice: match[1], ns: 'other' }))
    },
  },
  {
    condition: text => text.match(/The service can be renewed only after '([^']+)'/),
    action: ({ match }) => {
      toast.error(t('service_renew_error', { date: match[1], ns: 'other' }))
    },
  },
]

export default function checkIfTokenAlive(err, dispatch) {
  const uglyErrorText = err.message || err
  const errorText = uglyErrorText.trim()

  if (errorText === ERROR_ALREADY_HANDLED) return

  const matchedHandler = errorHandlers.find(handler => {
    const match = handler.condition(errorText)
    if (match) {
      handler.action({ dispatch, match })
      return true
    }
    return false
  })

  if (!matchedHandler) {
    console.error(err)

    if (isTranslationExists(errorText)) {
      toast.error(t(errorText, { ns: ['auth', 'other'] }))
    } else {
      toast.error(t('warnings.unknown_error', { ns: 'auth' }), {
        toastId: 'unknown_error',
        updateId: 'unknown_error',
      })
    }
  }

  return true
}
