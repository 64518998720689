import { useEffect, useState, useReducer } from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Formik, Form, ErrorMessage } from 'formik'
import { useTranslation } from 'react-i18next'
import * as routes from '@src/routes'
import {
  Select,
  InputField,
  Button,
  DomainItem,
  DedicItem,
  VdsItem,
  FtpItem,
  DnsItem,
  VhostItem,
  ForexItem,
  // SiteCareItem,
  // VpnItem,
  ScrollToFieldError,
  Icon,
  CustomPhoneInput,
  TooltipWrapper,
  PayersList,
  Cta,
  PolicyAgreement,
  Modal,
  PaymentMethodList,
  PaymentInfo,
} from '@components'

import {
  cartOperations,
  settingsOperations,
  payersSelectors,
  selectors,
  authSelectors,
  settingsSelectors,
  cartActions,
  userSelectors,
} from '@redux'
import * as Yup from 'yup'
import s from './Cart.module.scss'

import {
  useFormFraudCheckData,
  roundToDecimal,
  sortPaymethodList,
  parsePaymentInfo,
} from '@utils'
import { QIWI_PHONE_COUNTRIES, SBER_PHONE_COUNTRIES, OFFER_FIELD } from '@utils/constants'

export default function Component() {
  const dispatch = useDispatch()

  const navigate = useNavigate()

  const [state, setState] = useReducer((state, action) => {
    return { ...state, ...action }
  }, {})

  const { t } = useTranslation([
    'cart',
    'other',
    'payers',
    'billing',
    'dedicated_servers',
    'crumbs',
    'user_settings',
  ])

  const promotionsList = useSelector(selectors.getPromotionsList)

  const [paymentsMethodList, setPaymentsMethodList] = useState([])
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [isFree, setIsFree] = useState(false)
  const geoData = useSelector(authSelectors.getGeoData)

  const isLoading = useSelector(selectors.getIsLoading)
  const payersList = useSelector(payersSelectors.getPayersList)
  const payersSelectedFields = useSelector(payersSelectors.getPayersSelectedFields)
  const payersData = useSelector(payersSelectors.getPayersData)

  const filteredPayment_method = state.additionalPayMethodts?.find(
    e => e?.$key === state.selectedAddPaymentMethod,
  )

  const userEdit = useSelector(settingsSelectors.getUserEdit)
  const userInfo = useSelector(userSelectors.getUserInfo)

  const paymentListhandler = data => {
    const sortedList = sortPaymethodList(data)

    setPaymentsMethodList(sortedList)
    setState({ paymentListLoaded: true })
  }

  const setCartData = value => setState({ cartData: value })

  useEffect(() => {
    dispatch(cartOperations.getBasket(setCartData, paymentListhandler))
    dispatch(settingsOperations.getUserEdit(userInfo.$id))
  }, [])

  useEffect(() => {
    if (state.additionalPayMethodts && state.additionalPayMethodts?.length > 0) {
      setState({ selectedAddPaymentMethod: state.additionalPayMethodts[0]?.$key })
    }
  }, [state.additionalPayMethodts])

  useEffect(() => {
    if (state.cartData && !state.isPhoneVerification) {
      state.cartData?.elemList?.forEach(e => {
        if (e?.needphoneverify?.$ === 'on') {
          setState({ isPhoneVerification: true })
        }
      })
    }
  }, [state.cartData])

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (userEdit) {
      const findCountry = userEdit?.phone_countries?.find(
        e => e?.$key === userEdit?.phone_country,
      )
      const code = findCountry?.$image?.slice(-6, -4)?.toLowerCase()
      setState({ userCountryCode: code })
    }
  }, [userEdit])

  //isPersonalBalance

  const validationSchema = Yup.object().shape({
    profile:
      payersList?.length !== 0
        ? Yup.string().when('isPersonalBalance', {
            is: 'off',
            then: Yup.string().required(t('Choose payer')),
          })
        : null,
    payment_method:
      state.additionalPayMethodts && state.additionalPayMethodts?.length > 0
        ? Yup.string().required(t('Is a required field', { ns: 'other' }))
        : null,
    selectedPayMethod: Yup.object().required(t('Is a required field', { ns: 'other' })),
    phone:
      !filteredPayment_method?.hide?.includes('phone') &&
      filteredPayment_method?.hide?.includes('alfabank_login')
        ? Yup.string()
            .phone(
              state.countryCode,
              false,
              t('Must be a valid phone number', { ns: 'user_settings' }),
            )
            .required(t('Is a required field', { ns: 'other' }))
        : null,
    alfabank_login:
      filteredPayment_method?.hide?.includes('phone') &&
      !filteredPayment_method?.hide?.includes('alfabank_login')
        ? Yup.string().required(t('Is a required field', { ns: 'other' }))
        : null,
    [OFFER_FIELD]: Yup.bool().oneOf(
      [true],
      t('confirm license agreement', { ns: 'other' }),
    ),
  })

  const setPromocodeToCart = promocode => {
    dispatch(
      cartOperations.setBasketPromocode(promocode, setCartData, paymentListhandler),
    )
  }

  const deleteBasketItemHandler = item_id => {
    dispatch(cartOperations.deleteBasketItem(item_id, setCartData, paymentListhandler))
  }

  const closeBasketHandler = basket_id => {
    dispatch(cartOperations.clearBasket(basket_id))
  }

  const fraudData = useFormFraudCheckData()

  const payBasketHandler = values => {
    const data = {
      postcode_physical: values?.postcode_physical,
      eu_vat: values?.eu_vat,
      cnp: values?.cnp,
      city_legal: values?.city_physical,
      city_physical: values?.city_physical,
      address_legal: values?.address_physical,
      address_physical: values?.address_physical,
      postcode: values?.postcode_physical,
      city: values?.city_physical,
      address: values?.address_physical,
      country_physical:
        payersData.selectedPayerFields?.country ||
        payersData.selectedPayerFields?.country_physical ||
        payersSelectedFields?.country ||
        payersSelectedFields?.country_physical ||
        '',
      country_legal:
        payersData.selectedPayerFields?.country ||
        payersData.selectedPayerFields?.country_physical ||
        payersSelectedFields?.country ||
        payersSelectedFields?.country_physical ||
        '',
      billorder: state.cartData?.billorder,
      amount: state.cartData?.total_sum,
      profile: values?.profile,
      paymethod: values?.selectedPayMethod?.paymethod?.$,
      country:
        payersSelectedFields?.country || payersSelectedFields?.country_physical || '',
      profiletype: values?.profiletype || '',
      person:
        (payersList && payersList.find(e => e?.id?.$ === values?.profile)?.name?.$) ||
        values?.person ||
        ' ',
      director:
        (payersList && payersList.find(e => e?.id?.$ === values?.profile)?.name?.$) ||
        values?.person ||
        ' ',
      promocode: values?.promocode || '',
      [OFFER_FIELD]: values[OFFER_FIELD] ? 'on' : 'off',
    }

    if (values?.selectedPayMethod?.action?.button?.$name === 'fromsubaccount') {
      data['clicked_button'] = 'fromsubaccount'
    }

    if (values?.payment_method) {
      data['payment_method'] = values?.payment_method
    }

    if (values?.phone && values?.phone?.length > 0) {
      data['phone'] = values?.phone
    }

    if (values?.alfabank_login && values?.alfabank_login?.length > 0) {
      data['alfabank_login'] = values?.alfabank_login
    }

    /** ------- Analytics ------- */
    if (!values?.profile) {
      // Facebook pixel event
      window.fbq?.('track', 'AddPaymentInfo')
      // Quora pixel event
      if (window.qp) window.qp('track', 'AddPaymentInfo')
      // GTM
      window.dataLayer?.push({ event: 'AddPaymentInfo' })
    }

    if (window.qp) window.qp('track', 'InitiateCheckout')
    /** ------- /Analytics ------- */

    if (values.profiletype && values.profiletype !== '1') {
      data.jobtitle = payersData.selectedPayerFields?.jobtitle || 'jobtitle '
      data.rdirector = payersData.selectedPayerFields?.rdirector || 'rdirector '
      data.rjobtitle = payersData.selectedPayerFields?.rjobtitle || 'rjobtitle '
      data.ddirector = payersData.selectedPayerFields?.ddirector || 'ddirector '
      data.djobtitle = payersData.selectedPayerFields?.djobtitle || 'djobtitle '
      data.baseaction = payersData.selectedPayerFields?.baseaction || 'baseaction '
      data.name = values?.name || ''
    }

    const cart = { ...state.cartData, paymethod_name: values?.selectedPayMethod?.name?.$ }

    dispatch(cartOperations.setPaymentMethods(data, navigate, cart, fraudData))
  }

  const hideBasketHandler = () => {
    navigate(routes.PHONE_VERIFICATION, {
      state: { orderPage: location.pathname },
    })
    dispatch(cartActions.setCartIsOpenedState({ isOpened: false }))
  }

  let VDS_FEE_AMOUNT = ''

  const renderItems = () => {
    const domainsList = state.cartData?.elemList?.filter(
      elem => elem['item.type']?.$ === 'domain',
    )
    const dedicList = state.cartData?.elemList?.filter(
      elem => elem['item.type']?.$ === 'dedic',
    )
    const vdsList = state.cartData?.elemList?.filter(
      elem => elem['item.type']?.$ === 'vds',
    )
    const ftpList = state.cartData?.elemList?.filter(
      elem => elem['item.type']?.$ === 'storage',
    )
    const dnsList = state.cartData?.elemList?.filter(
      elem => elem['item.type']?.$ === 'dnshost',
    )
    const forexList = state.cartData?.elemList?.filter(
      elem => elem['item.type']?.$ === 'forexbox',
    )
    const vhostList = state.cartData?.elemList?.filter(
      elem => elem['item.type']?.$ === 'vhost',
    )
    // const siteCareList = state.cartData?.elemList?.filter(
    //   elem => elem['item.type']?.$ === 'zabota-o-servere',
    // )
    // const vpnList = state.cartData?.elemList?.filter(
    //   elem => elem['item.type']?.$ === 'vpn',
    // )
    const cloudList = state.cartData?.elemList?.filter(
      elem => elem['item.type']?.$ === 'instances',
    )

    const filteredVdsList = []

    vdsList?.forEach(elem => {
      if (
        filteredVdsList?.filter(e => e?.pricelist_name?.$ === elem?.pricelist_name?.$)
          ?.length === 0
      ) {
        filteredVdsList?.push({
          ...elem,
          count: vdsList.filter(e => e?.pricelist_name?.$ === elem?.pricelist_name?.$)
            ?.length,
        })
      }
    })

    //penalty for vds
    const vdsWithPenalty = vdsList?.filter(el => {
      return el?.desc?.$?.includes('fee will be charged')
    })

    const VDS_FEE_AMOUNT_ARRAY = []

    if (vdsWithPenalty?.length > 0) {
      vdsWithPenalty.forEach(el => {
        const penaltyPrice = el?.desc?.$?.match(/time: (.+?)(?= EUR)/)?.[1]
        VDS_FEE_AMOUNT_ARRAY.push(penaltyPrice)
      })
    }
    const vdsTotalPenalty = VDS_FEE_AMOUNT_ARRAY?.reduce(
      (acc, curr) => Number(curr) + Number(acc),
      0,
    )

    VDS_FEE_AMOUNT = vdsTotalPenalty
    //penalty for vds

    const filteredDnsList = []

    dnsList?.forEach(elem => {
      if (
        filteredDnsList?.filter(e => e?.pricelist_name?.$ === elem?.pricelist_name?.$)
          ?.length === 0
      ) {
        filteredDnsList?.push({
          ...elem,
          count: dnsList.filter(e => e?.pricelist_name?.$ === elem?.pricelist_name?.$)
            ?.length,
        })
      }
    })

    const filteredFtpList = []

    ftpList?.forEach(elem => {
      if (
        filteredFtpList?.filter(e => e?.pricelist_name?.$ === elem?.pricelist_name?.$)
          ?.length === 0
      ) {
        filteredFtpList?.push({
          ...elem,
          count: ftpList.filter(e => e?.pricelist_name?.$ === elem?.pricelist_name?.$)
            ?.length,
        })
      }
    })

    const filteredForexList = []

    forexList?.forEach(elem => {
      if (
        filteredForexList?.filter(e => e?.pricelist_name?.$ === elem?.pricelist_name?.$)
          ?.length === 0
      ) {
        filteredForexList?.push({
          ...elem,
          count: forexList.filter(e => e?.pricelist_name?.$ === elem?.pricelist_name?.$)
            ?.length,
        })
      }
    })

    const filteredDedicList = []

    dedicList?.forEach(elem => {
      if (
        filteredDedicList?.filter(e => e?.pricelist_name?.$ === elem?.pricelist_name?.$)
          ?.length === 0
      ) {
        filteredDedicList?.push({
          ...elem,
          count: dedicList.filter(e => e?.pricelist_name?.$ === elem?.pricelist_name?.$)
            ?.length,
        })
      }
    })

    const filteredVhostList = []

    vhostList?.forEach(elem => {
      if (
        filteredVhostList?.filter(e => e?.pricelist_name?.$ === elem?.pricelist_name?.$)
          ?.length === 0
      ) {
        filteredVhostList?.push({
          ...elem,
          count: vhostList.filter(e => e?.pricelist_name?.$ === elem?.pricelist_name?.$)
            ?.length,
        })
      }
    })

    const filteredCloudList = []

    cloudList?.forEach(elem => {
      if (
        filteredCloudList?.filter(e => e?.pricelist_name?.$ === elem?.pricelist_name?.$)
          ?.length === 0
      ) {
        filteredCloudList?.push({
          ...elem,
          count: cloudList.filter(e => e?.pricelist_name?.$ === elem?.pricelist_name?.$)
            ?.length,
        })
      }
    })

    const maxItemsToShow = screenWidth < 768 ? 2 : 3
    let displayedItems = []

    switch (true) {
      // case vpnList?.length > 0:
      //   displayedItems = state.showAllItems ? vpnList : vpnList.slice(0, maxItemsToShow)
      //   break
      // case siteCareList?.length > 0:
      //   displayedItems = state.showAllItems
      //     ? siteCareList
      //     : siteCareList.slice(0, maxItemsToShow)
      //   break
      case filteredVhostList?.length > 0:
        displayedItems = state.showAllItems
          ? filteredVhostList
          : filteredVhostList.slice(0, maxItemsToShow)
        break
      case domainsList?.length > 0:
        displayedItems = state.showAllItems
          ? domainsList
          : domainsList.slice(0, maxItemsToShow)
        break
      case filteredDedicList?.length > 0:
        displayedItems = state.showAllItems
          ? filteredDedicList
          : filteredDedicList.slice(0, maxItemsToShow)
        break
      case filteredVdsList?.length > 0:
        displayedItems = state.showAllItems
          ? filteredVdsList
          : filteredVdsList.slice(0, maxItemsToShow)
        break
      case filteredFtpList?.length > 0:
        displayedItems = state.showAllItems
          ? filteredFtpList
          : filteredFtpList.slice(0, maxItemsToShow)
        break
      case filteredDnsList?.length > 0:
        displayedItems = state.showAllItems
          ? filteredDnsList
          : filteredDnsList.slice(0, maxItemsToShow)
        break
      case filteredForexList?.length > 0:
        displayedItems = state.showAllItems
          ? filteredForexList
          : filteredForexList.slice(0, maxItemsToShow)
        break
      case filteredCloudList?.length > 0:
        displayedItems = state.showAllItems
          ? filteredCloudList
          : filteredCloudList.slice(0, maxItemsToShow)
        break
      default:
        break
    }

    const shouldRenderButton = listLength =>
      screenWidth < 768 ? listLength > 2 : listLength > 3

    const showMoreButton = listLength => {
      const toggleShowAllItems = () => setState({ showAllItems: !state.showAllItems })

      return (
        <Cta
          viewType="text"
          view="primary"
          onClick={toggleShowAllItems}
          className={s.showMoreItemsBtn}
        >
          {!state.showAllItems
            ? `${t('Show')} ${listLength - displayedItems.length} ${t('more items')}`
            : t('Hide')}
        </Cta>
      )
    }

    return (
      <>
        {/* {vpnList?.length > 0 && (
          <>
            <div className={s.formBlockTitle}>VPN:</div>
            <div className={cn(s.elements_wrapper, { [s.opened]: state.showAllItems })}>
              {displayedItems?.map(el => {
                const { id, desc, cost, pricelist_name, discount_percent, fullcost } = el
                return (
                  <VpnItem
                    key={id?.$}
                    desc={desc?.$}
                    cost={cost?.$}
                    discount_percent={discount_percent?.$}
                    fullcost={fullcost?.$}
                    itemId={el['item.id']?.$}
                    pricelist_name={pricelist_name?.$}
                    deleteItemHandler={
                      domainsList?.length > 1
                        ? () => deleteBasketItemHandler(id?.$)
                        : null
                    }
                    period={el['item.period']?.$}
                  />
                )
              })}
            </div>
            {shouldRenderButton(vpnList.length) && showMoreButton(vpnList.length)}
          </>
        )} */}

        {/* {siteCareList?.length > 0 && (
          <>
            <div className={s.formBlockTitle}>{t('Site care')}:</div>
            <div className={cn(s.elements_wrapper, { [s.opened]: state.showAllItems })}>
              {displayedItems?.map(el => {
                const {
                  id,
                  desc,
                  cost,
                  pricelist_name,
                  discount_percent,
                  fullcost,
                  count,
                } = el
                return (
                  <SiteCareItem
                    key={id?.$}
                    desc={desc?.$}
                    cost={cost?.$}
                    discount_percent={discount_percent?.$}
                    fullcost={fullcost?.$}
                    itemId={el['item.id']?.$}
                    pricelist_name={pricelist_name?.$}
                    deleteItemHandler={
                      domainsList?.length > 1
                        ? () => deleteBasketItemHandler(id?.$)
                        : null
                    }
                    count={count}
                    period={el['item.period']?.$}
                  />
                )
              })}
            </div>
            {shouldRenderButton(siteCareList.length) &&
              showMoreButton(siteCareList.length)}
          </>
        )} */}

        {filteredVhostList?.length > 0 && (
          <div>
            <div className={s.formBlockTitle}>{t('vhost', { ns: 'crumbs' })}:</div>
            <div
              className={cn(s.elements_wrapper, s.vhost, {
                [s.opened]: state.showAllItems,
              })}
            >
              {displayedItems?.map(el => {
                const {
                  id,
                  desc,
                  cost,
                  pricelist_name,
                  discount_percent,
                  fullcost,
                  count,
                } = el
                return (
                  <VhostItem
                    key={id?.$}
                    desc={desc?.$}
                    cost={cost?.$}
                    discount_percent={discount_percent?.$}
                    fullcost={fullcost?.$}
                    itemId={el['item.id']?.$}
                    pricelist_name={pricelist_name?.$}
                    deleteItemHandler={
                      filteredVhostList?.length > 1
                        ? () => deleteBasketItemHandler(id?.$)
                        : null
                    }
                    count={count}
                    period={el['item.period']?.$}
                  />
                )
              })}
            </div>
            {shouldRenderButton(filteredVhostList.length) &&
              showMoreButton(filteredVhostList.length)}
          </div>
        )}

        {domainsList?.length > 0 && (
          <>
            <div>
              <div className={s.formBlockTitle}>{t('Domain registration')}:</div>
              <div
                className={cn(s.elements_wrapper, s.domain, {
                  [s.opened]: state.showAllItems,
                })}
              >
                {displayedItems?.map(el => {
                  const { id, desc, cost, fullcost, discount_percent } = el
                  return (
                    <DomainItem
                      key={id?.$}
                      desc={desc?.$}
                      cost={cost?.$}
                      fullcost={fullcost?.$}
                      discount_percent={discount_percent?.$}
                      deleteItemHandler={
                        domainsList?.length > 1
                          ? () => deleteBasketItemHandler(id?.$)
                          : null
                      }
                      period={el['item.period']?.$}
                    />
                  )
                })}
              </div>
            </div>
            {shouldRenderButton(domainsList.length) && showMoreButton(domainsList.length)}
          </>
        )}

        {filteredDedicList?.length > 0 && (
          <div>
            <div className={s.formBlockTitle}>
              {t('dedicated_server', { ns: 'dedicated_servers' })}:
            </div>
            <div className={cn(s.elements_wrapper, { [s.opened]: state.showAllItems })}>
              {displayedItems?.map(el => {
                const {
                  id,
                  desc,
                  cost,
                  fullcost,
                  discount_percent,
                  pricelist_name,
                  count,
                } = el
                return (
                  <DedicItem
                    key={id?.$}
                    desc={desc?.$}
                    cost={cost?.$}
                    fullcost={fullcost?.$}
                    discount_percent={discount_percent?.$}
                    pricelist_name={pricelist_name?.$}
                    count={count}
                    deleteItemHandler={
                      filteredDedicList?.length > 1
                        ? () => deleteBasketItemHandler(id?.$)
                        : null
                    }
                    period={el['item.period']?.$}
                  />
                )
              })}
            </div>
            {shouldRenderButton(filteredVhostList.length) &&
              showMoreButton(filteredDedicList?.length)}
          </div>
        )}

        {filteredVdsList?.length > 0 && (
          <div className={s.vds_wrapper}>
            <div className={cn(s.formBlockTitle)}>
              {t('services.Virtual server', { ns: 'other' })}:
            </div>

            <div>
              <div className={cn(s.elements_wrapper, { [s.opened]: state.showAllItems })}>
                {displayedItems?.map(el => {
                  return (
                    <VdsItem
                      key={el?.id?.$}
                      el={el}
                      deleteItemHandler={
                        filteredVdsList?.length > 1
                          ? () => deleteBasketItemHandler(el?.id?.$)
                          : null
                      }
                    />
                  )
                })}
              </div>
              {shouldRenderButton(filteredVdsList.length) &&
                showMoreButton(filteredVdsList?.length)}
            </div>
          </div>
        )}

        {filteredFtpList?.length > 0 && (
          <div>
            <div className={s.formBlockTitle}>
              {t('services.External FTP-storage', { ns: 'other' })}:{' '}
            </div>
            <div className={cn(s.elements_wrapper, { [s.opened]: state.showAllItems })}>
              {displayedItems?.map(el => {
                const {
                  id,
                  desc,
                  cost,
                  fullcost,
                  discount_percent,
                  pricelist_name,
                  count,
                } = el
                return (
                  <FtpItem
                    key={id?.$}
                    desc={desc?.$}
                    cost={cost?.$}
                    fullcost={fullcost?.$}
                    discount_percent={discount_percent?.$}
                    pricelist_name={pricelist_name?.$}
                    count={count}
                    deleteItemHandler={
                      filteredFtpList?.length > 1
                        ? () => deleteBasketItemHandler(id?.$)
                        : null
                    }
                    period={el['item.period']?.$}
                  />
                )
              })}
            </div>
            {shouldRenderButton(filteredFtpList.length) &&
              showMoreButton(filteredFtpList.length)}
          </div>
        )}

        {filteredDnsList?.length > 0 && (
          <div>
            <div className={s.formBlockTitle}>{t('dns', { ns: 'crumbs' })}:</div>
            <div className={cn(s.elements_wrapper, { [s.opened]: state.showAllItems })}>
              {displayedItems?.map(el => {
                const {
                  id,
                  desc,
                  cost,
                  fullcost,
                  discount_percent,
                  pricelist_name,
                  count,
                } = el
                return (
                  <DnsItem
                    key={id?.$}
                    desc={desc?.$}
                    cost={cost?.$}
                    fullcost={fullcost?.$}
                    discount_percent={discount_percent?.$}
                    pricelist_name={pricelist_name?.$}
                    count={count}
                    deleteItemHandler={
                      filteredDnsList?.length > 1
                        ? () => deleteBasketItemHandler(id?.$)
                        : null
                    }
                    period={el['item.period']?.$}
                  />
                )
              })}
            </div>
            {shouldRenderButton(filteredFtpList.length) &&
              showMoreButton(filteredDnsList?.length)}
          </div>
        )}

        {filteredForexList?.length > 0 && (
          <div>
            <div className={s.formBlockTitle}>{t('forex', { ns: 'crumbs' })}:</div>
            <div
              className={cn(s.elements_wrapper, s.forex, {
                [s.opened]: state.showAllItems,
              })}
            >
              {displayedItems?.map(el => {
                const {
                  id,
                  desc,
                  cost,
                  fullcost,
                  discount_percent,
                  pricelist_name,
                  count,
                } = el
                return (
                  <ForexItem
                    key={id?.$}
                    desc={desc?.$}
                    cost={cost?.$}
                    fullcost={fullcost?.$}
                    discount_percent={discount_percent?.$}
                    pricelist_name={pricelist_name?.$}
                    count={count}
                    deleteItemHandler={
                      filteredForexList?.length > 1
                        ? () => deleteBasketItemHandler(id?.$)
                        : null
                    }
                    period={el['item.period']?.$}
                  />
                )
              })}
            </div>
            {shouldRenderButton(filteredForexList?.length) &&
              showMoreButton(filteredForexList?.length)}
          </div>
        )}

        {filteredCloudList?.length > 0 && (
          <div className={s.vds_wrapper}>
            <div className={cn(s.formBlockTitle)}>
              {t('cloud_vps', { ns: 'crumbs' })}:
            </div>

            <div>
              <div className={cn(s.elements_wrapper, { [s.opened]: state.showAllItems })}>
                {displayedItems?.map(el => {
                  return (
                    <VdsItem
                      key={el?.id?.$}
                      el={el}
                      deleteItemHandler={
                        filteredCloudList?.length > 1
                          ? () => deleteBasketItemHandler(el?.id?.$)
                          : null
                      }
                    />
                  )
                })}
              </div>
              {shouldRenderButton(filteredCloudList.length) &&
                showMoreButton(filteredCloudList?.length)}
            </div>
          </div>
        )}
      </>
    )
  }

  const renderActiveDiscounts = () => {
    // Get all price_hints from each element in the elemList and combine them
    const arr = state.cartData?.elemList
      ?.map(item => item.price_hint?.$?.split('<br/>'))
      .flat()

    const services = arr?.filter(
      e =>
        !e?.includes('Active discounts') &&
        !e?.includes('Total discount') &&
        e?.length > 0,
    )

    const total = arr
      ?.find(e => e?.includes('Total discount'))
      ?.replace('Total discount ~', '')

    return (
      <div>
        <b>{t('Active discounts')}</b>
        <br />
        <br />
        {services?.map(e => {
          const [tariff, price] = e.split(' -')

          const tariffName =
            tariff.split(' /')[0] +
            (tariff.includes('setup fee') ? ' (initial setup)' : '')
          const renderString = [tariffName, price].join(' -')

          function getDiscount(str) {
            let result = str?.match(/(-?\d+(\.\d+)?%)/g)
            return result.at(0) === '0%' ? [] : result
          }
          if (getDiscount(e)?.length !== 0) {
            return (
              <p
                key={e}
                className={s.discItem}
                dangerouslySetInnerHTML={{
                  __html: renderString
                    ?.replace(' -', ':')
                    ?.replace(
                      getDiscount(renderString)[0],
                      `<span style='color: #FA6848'>-${getDiscount(e)[0]}</span>`,
                    ),
                }}
              />
            )
          }
        })}
        <br />
        <div className={s.actLine} />
        <br />
        {t('Total discounts')}: ≈ {total}
      </div>
    )
  }

  /**
   * In this useEffect we check for the dedic half year promotion
   * if this promotion for dedic is enabled and it is dedic ordering -
   * we disable promocode field
   */
  useEffect(() => {
    const isItDedic =
      state.cartData?.elemList?.[0]?.pricelist_name.$.toLowerCase().includes('config')

    if (isItDedic) {
      const cartConfigName = state.cartData?.elemList[0]?.pricelist_name.$?.slice(
        0,
        state.cartData?.elemList[0]?.pricelist_name.$.indexOf('/') - 1,
      )

      let foundSale
      /**
       * This if statement is for two versions of API
       * This first block is for a new version
       */
      if (promotionsList?.[0]?.products) {
        foundSale = promotionsList.find(sale => sale.products.$.includes(cartConfigName))

        /**
         * and this second block is for an old version,
         * which should be removed after API updating
         */
      } else {
        foundSale = promotionsList.find(
          sale =>
            sale.promotion?.$ === 'Большие скидки на выделенные серверы' &&
            sale.idname.$.includes(cartConfigName),
        )
      }

      const cartDiscountPercent =
        state.cartData?.elemList[0]?.discount_percent?.$.replace('%', '') || 0
      const selectedPeriod = state.cartData?.elemList[0]?.['item.period']?.$

      if (foundSale) {
        if (
          (selectedPeriod === '12' && Number(cartDiscountPercent) <= 8) ||
          (selectedPeriod === '24' && Number(cartDiscountPercent) <= 10) ||
          (selectedPeriod === '36' && Number(cartDiscountPercent) <= 12) ||
          cartDiscountPercent === 0
        ) {
          setState({ isDedicWithSale: false })
        } else {
          setState({ isDedicWithSale: true })
        }
      }
    }
  }, [promotionsList, state.cartData?.elemList])

  const renderPayersListTitle = () => (
    <div className={s.formBlockTitle}>{t('Payer')}:</div>
  )

  return (
    <div className={cn(s.modalBg, { [s.closing]: state.isClosing })}>
      {!state.isClosing ? (
        <Modal
          closeModal={() => setState({ isClosing: true })}
          isOpen
          className={cn(s.modalBlock, {
            [s.visible]: payersSelectedFields && !!payersData.selectedPayerFields,
          })}
        >
          <Modal.Header>
            <div>
              <p className={'headings_h2'}>{t('Payment')}</p>
            </div>
          </Modal.Header>
          <Modal.Body>
            {payersSelectedFields && <div className={s.itemsBlock}>{renderItems()}</div>}

            <Formik
              enableReinitialize
              validationSchema={validationSchema}
              initialValues={{
                profile:
                  payersData.selectedPayerFields?.profile ||
                  payersList?.[payersList?.length - 1]?.id?.$ ||
                  '',
                name:
                  payersData.state?.name || payersData.selectedPayerFields?.name || '',
                address_physical:
                  payersData.state?.addressPhysical ??
                  payersData.selectedPayerFields?.address_physical ??
                  '',
                postcode_physical:
                  payersData.state?.postcodePhysical ??
                  payersData.selectedPayerFields?.postcode_physical ??
                  '',
                city_physical:
                  payersData.state?.cityPhysical ??
                  (payersData.selectedPayerFields?.city_physical ||
                    geoData?.clients_city ||
                    ''),
                person:
                  payersData.state?.person ??
                  payersData.selectedPayerFields?.person ??
                  '',
                country:
                  payersSelectedFields?.country ||
                  payersSelectedFields?.country_physical ||
                  '',
                profiletype:
                  payersData.state?.profiletype ||
                  payersData.selectedPayerFields?.profiletype ||
                  payersSelectedFields?.profiletype,
                eu_vat:
                  payersData.state?.euVat || payersData.selectedPayerFields?.eu_vat || '',
                cnp: payersData.state?.cnp || payersData.selectedPayerFields?.cnp || '',
                [OFFER_FIELD]: state.isPolicyChecked || false,

                selectedPayMethod: state.selectedPayMethod || paymentsMethodList?.[0],
                promocode: state.promocode || '',
                isPersonalBalance:
                  state.selectedPayMethod?.name?.$?.includes('balance') &&
                  state.selectedPayMethod?.paymethod_type?.$ === '0'
                    ? 'on'
                    : 'off',
                phone: state.phone || '',
                payment_method: state.selectedAddPaymentMethod || undefined,
                alfabank_login: state.alfaLogin || '',
              }}
              onSubmit={payBasketHandler}
            >
              {({ values, setFieldValue, touched, errors, handleBlur }) => {
                useEffect(() => {
                  if (Number(state.cartData?.total_sum) === 0) {
                    setIsFree(true)
                    const balancePaymethod = paymentsMethodList.find(el =>
                      el.name?.$.includes('Account balance'),
                    )

                    setFieldValue('selectedPayMethod', balancePaymethod)
                    setFieldValue('isPersonalBalance', 'on')
                    setState({
                      selectedPayMethod: balancePaymethod,
                      selectedAddPaymentMethod: undefined,
                    })
                  }
                }, [state.cartData?.total_sum, paymentsMethodList])

                const renderPhoneList = paymethod => {
                  if (paymethod === 'qiwi') {
                    return QIWI_PHONE_COUNTRIES
                  } else if (paymethod === 'sberbank') {
                    return SBER_PHONE_COUNTRIES
                  } else {
                    return []
                  }
                }

                const setAdditionalPayMethodts = value =>
                  setState({ additionalPayMethodts: value })

                useEffect(() => {
                  dispatch(
                    cartOperations.getPayMethodItem(
                      {
                        paymethod: paymentsMethodList[0]?.paymethod?.$,
                      },
                      setAdditionalPayMethodts,
                    ),
                  )
                }, [paymentsMethodList])

                return (
                  <Form className={s.formBlockList} id="cartForm">
                    <ScrollToFieldError />
                    {!isFree && (
                      <div className={s.formBlock}>
                        {!isLoading &&
                          state.paymentListLoaded &&
                          paymentsMethodList?.length === 0 && (
                            <div className={s.notAllowPayMethod}>
                              {t('order_amount_is_less')}
                            </div>
                          )}
                        {paymentsMethodList?.length > 0 && !state.isPhoneVerification && (
                          <>
                            <div className={s.formBlockTitle}>{t('Payment method')}:</div>
                            <PaymentMethodList
                              methods={paymentsMethodList}
                              selectedMethod={values.selectedPayMethod}
                              onMethodSelect={method => {
                                setFieldValue('selectedPayMethod', method)
                                setState({
                                  selectedPayMethod: method,
                                  selectedAddPaymentMethod: undefined,
                                })
                              }}
                              state={state}
                              setState={setState}
                              setFieldValue={setFieldValue}
                              setAdditionalPayMethodts={setAdditionalPayMethodts}
                              wrapperClassName={s.blockWrapper}
                            />
                          </>
                        )}

                        {state.additionalPayMethodts && (
                          <div className={s.additionalPayMethodBlock}>
                            {state.additionalPayMethodts?.length > 1 && (
                              <Select
                                placeholder={t('Not chosen', { ns: 'other' })}
                                label={`${t('Payment method')} Yookasa:`}
                                value={values.payment_method}
                                getElement={item => {
                                  setFieldValue('payment_method', item)
                                  setState({ selectedAddPaymentMethod: item })
                                }}
                                className={s.select}
                                dropdownClass={s.selectDropdownClass}
                                itemsList={state.additionalPayMethodts?.map(
                                  ({ $key, $ }) => ({
                                    label: t(`${$.trim()}`, { ns: 'billing' }),
                                    value: $key,
                                  }),
                                )}
                                error={errors.payment_method}
                                isRequired
                                isColored
                              />
                            )}

                            {filteredPayment_method?.hide?.includes('phone') &&
                              !filteredPayment_method?.hide?.includes(
                                'alfabank_login',
                              ) && (
                                <InputField
                                  inputWrapperClass={s.inputHeight}
                                  name="alfabank_login"
                                  label={`${t('Имя пользователя в Альфа-Клик', {
                                    ns: 'payers',
                                  })}:`}
                                  placeholder={t('Enter data', { ns: 'other' })}
                                  className={cn(
                                    s.inputBig,
                                    s.additionalSelectPayMentMethod,
                                  )}
                                  error={!!errors.alfabank_login}
                                  touched={!!touched.alfabank_login}
                                  isRequired
                                  onChange={e => setState({ alfaLogin: e.target.value })}
                                />
                              )}

                            {!filteredPayment_method?.hide?.includes('phone') &&
                              filteredPayment_method?.hide?.includes(
                                'alfabank_login',
                              ) && (
                                <CustomPhoneInput
                                  value={values.phone}
                                  label={`${t('Phone', { ns: 'other' })}:`}
                                  handleBlur={handleBlur}
                                  setFieldValue={(name, value) => {
                                    setFieldValue(name, value)
                                    setState({ phone: value })
                                  }}
                                  name="phone"
                                  onlyCountries={renderPhoneList(
                                    filteredPayment_method?.$key,
                                  )}
                                  isRequired
                                  setCountryCode={value =>
                                    setState({ countryCode: value })
                                  }
                                  country={
                                    state.countryCode ||
                                    renderPhoneList(filteredPayment_method?.$key)?.[0]
                                  }
                                />
                              )}
                          </div>
                        )}

                        <ErrorMessage
                          className={s.error_message}
                          name={'selectedPayMethod'}
                          component="span"
                        />
                      </div>
                    )}

                    <PaymentInfo
                      selectedPayMethod={values?.selectedPayMethod}
                      parsedText={parsePaymentInfo(values?.selectedPayMethod?.desc?.$)}
                      readMoreThresholds={{
                        minWidth170: 170,
                        minWidth106: 110,
                        default: 150,
                      }}
                    />

                    <div
                      className={cn(s.formBlock, {
                        [s.hide]: !values?.selectedPayMethod,
                      })}
                    >
                      <PayersList renderTitle={renderPayersListTitle} />
                    </div>

                    {!state.isPhoneVerification && !isFree && (
                      <div>
                        <div className={s.formFieldsBlock}>
                          <InputField
                            inputWrapperClass={s.inputHeight}
                            name="promocode"
                            disabled={state.isDedicWithSale}
                            label={`${t('Promo code')}:`}
                            placeholder={t('Enter promo code', { ns: 'other' })}
                            className={s.inputPerson}
                            error={!!errors.promocode}
                            touched={!!touched.promocode}
                            value={values.promocode}
                            onChange={e => setState({ promocode: e.target.value })}
                          />

                          <Cta
                            buttonType="button"
                            view="secondary"
                            viewType="button"
                            onClick={() => setPromocodeToCart(values?.promocode)}
                            className={'body_m'}
                            disabled={Boolean(!values?.promocode?.length)}
                          >
                            {t('Apply', { ns: 'other' })}
                          </Cta>
                        </div>

                        {state.isDedicWithSale ? (
                          <div className={cn('body_s', s.sale55Promo)}>
                            {t('dedic_sale_text')}
                          </div>
                        ) : null}
                      </div>
                    )}

                    {VDS_FEE_AMOUNT && VDS_FEE_AMOUNT > 0 ? (
                      <div className={cn(s.penalty_sum)}>
                        {t('Late fee')}: <b>€{roundToDecimal(VDS_FEE_AMOUNT)}</b>
                      </div>
                    ) : (
                      ''
                    )}

                    <div>
                      {!state.isPhoneVerification && (
                        <PolicyAgreement
                          fieldName={OFFER_FIELD}
                          fieldValue={values[OFFER_FIELD]}
                          error={!!errors[OFFER_FIELD]}
                          touched={!!touched[OFFER_FIELD]}
                          onCheck={() =>
                            setState({ isPolicyChecked: !state.isPolicyChecked })
                          }
                          wrapperClassName={s.cart_policy}
                        />
                      )}

                      {state.isPhoneVerification && (
                        <div className={s.phoneVerificationBlock}>
                          <Icon name="Attention" />
                          <span>
                            {t('verification_required_purchase', { ns: 'billing' })}
                          </span>
                        </div>
                      )}
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </Modal.Body>

          <Modal.Footer className={s.footer_wrapper}>
            <div className={s.totalSum}>
              <span className={s.price_row}>
                {state.cartData?.full_discount &&
                Number(state.cartData?.full_discount) !== 0 ? (
                  <>
                    {t('Saving')}: <b>€{roundToDecimal(state.cartData?.full_discount)}</b>
                    <TooltipWrapper
                      className={s.InfoBtn}
                      wrapperClassName={s.infoBtnCard}
                      content={renderActiveDiscounts()}
                    >
                      <Icon name="NewInfoIcon" />
                    </TooltipWrapper>
                  </>
                ) : null}
              </span>
              {Number(state.cartData?.tax) > 0 ? (
                <div className={cn('other_btn_uppercase', s.priceBlock)}>
                  {t('Tax')}:<b>€{roundToDecimal(state.cartData?.tax)}</b>
                </div>
              ) : null}
              <div className={cn('other_btn_uppercase', s.priceBlock)}>
                {t('Total')}
                {Number(state.cartData?.tax) > 0 &&
                  ' (' + t('Tax included').toLocaleLowerCase() + ')'}
                <span className="headings_h4_medium">
                  €{roundToDecimal(state.cartData?.total_sum)}
                </span>
              </div>
            </div>

            <div className={s.buttonsWrapper}>
              {state.isPhoneVerification ? (
                <Button
                  className={s.saveBtn}
                  size="large"
                  label={t('Verify number', { ns: 'user_settings' })}
                  type="button"
                  onClick={hideBasketHandler}
                />
              ) : (
                <>
                  {paymentsMethodList?.length === 0 ? (
                    <Button
                      className={s.saveBtn}
                      size="medium"
                      label={t('OK', { ns: 'billing' })}
                      type="button"
                      onClick={() => {
                        navigate(routes.BILLING, {
                          replace: true,
                        })
                        closeBasketHandler(state.cartData?.billorder)
                      }}
                    />
                  ) : (
                    <Button
                      className={s.saveBtn}
                      label={
                        isFree
                          ? t('Activate', { ns: 'billing' })
                          : t('Pay', { ns: 'billing' })
                      }
                      type="submit"
                      form="cartForm"
                    />
                  )}
                </>
              )}

              <Cta
                type="button"
                view="secondary"
                onClick={() => setState({ isClosing: true })}
                className={'body_m'}
              >
                {t('Close', { ns: 'other' })}
              </Cta>
            </div>
          </Modal.Footer>
        </Modal>
      ) : (
        <Modal
          closeModal={() => setState({ isClosing: false })}
          isOpen
          className={s.closeModal}
        >
          <Modal.Body className={s.modalCloseBlock}>
            <div className={cn('body_l_regular', s.closeText)}>
              {t('After closing your order will be automatically deleted')}
            </div>
            <div className={s.closeModal__key_wrapper}>
              <Button
                onClick={() => closeBasketHandler(state.cartData?.billorder)}
                label={t('OK')}
                type="button"
              />

              <Cta
                buttonType="button"
                view="secondary"
                onClick={() => setState({ isClosing: false })}
                className={'body_m'}
              >
                {t('Cancel', { ns: 'other' })}
              </Cta>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  )
}
