import { useTranslation } from 'react-i18next'
import {
  EditCell,
  Icon,
  ImagesOptions,
  CardsContainer,
  Table,
  TooltipWrapper,
  Status,
} from '@components'
import cn from 'classnames'
import { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import ImageItem from './ImageItem'
import ImageMobileItem from './ImageMobileItem'
import {
  formatCountryName,
  getFlagFromCountryName,
  getFormatedDate,
  getImageIconName,
  getImageInfo,
  roundToDecimal,
} from '@utils'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import s from './ImagesList.module.scss'
import { useSelector } from 'react-redux'
import { selectors } from '@redux'
import { STATUS_TYPES } from '@utils/constants'

export default function ImagesList({
  items,
  cells,
  getItems,
  editImage,
  idKey = 'id',
  pageList,
  cost,
  itemOnClickHandler,
  disableRestore,
  stickyHead,
  isFiltered,
  sortBy,
  setContextData,
}) {
  const { t } = useTranslation(['cloud_vps', 'countries', 'filter'])
  const widerThan1024 = useMediaQuery({ query: '(min-width: 1024px)' })
  const darkTheme = useSelector(selectors.getTheme) === 'dark'
  const { id: instanceId } = useParams()

  const getItemsHandler = values => getItems({ ...values })

  useEffect(() => {
    setContextData({
      hasPagination: true,
      paginationSettings: { getItems: getItemsHandler, pageList },
    })

    const p_num = localStorage.getItem(`p_num.${[pageList]}`)
    const sort = p_num ? {} : sortBy
    getItemsHandler({ p_num: p_num ?? 1, ...sort })

    return () => setContextData({ hasPagination: false })
  }, [])

  const renderHeadCells = () =>
    cells
      .filter(cell => !cell.isHidden)
      .map(cell => {
        return (
          <th key={cell.label} data-th={cell.label}>
            {t(cell.label)}
          </th>
        )
      })

  const renderCells = cells.map(cell => {
    let renderData
    switch (cell.label) {
      case 'name':
        if (!cell.renderData) {
          renderData = function renderData(value, item) {
            return (
              <div className={s.name_wrapper}>
                <div className={s.name_field_wrapper}>
                  {(item?.protected?.$orig === 'on' || item?.protected?.$ === 'on') && (
                    <TooltipWrapper
                      className={s.popup}
                      wrapperClassName={s.popup__wrapper}
                      content={t('image.protected')}
                    >
                      <Icon name="Protected" />
                    </TooltipWrapper>
                  )}
                  <div className={s.name_field}>
                    <EditCell
                      originName={value}
                      onSubmit={value => {
                        if (value) {
                          const name = value.trim()
                          editImage({ id: item[idKey].$, name })
                        }
                      }}
                      placeholder={value || t('server_placeholder', { ns: 'vds' })}
                    />
                  </div>
                </div>
                <span className={cn('text-dark-50', 'body_s')}>ID: {item.id.$}</span>
              </div>
            )
          }
          return { ...cell, renderData }
        }
        return cell
      case 'status':
        if (!cell.renderData) {
          renderData = function renderData(_, item) {
            const { displayStatus, status } = getImageInfo(item)

            return (
              <>
                {displayStatus && (
                  <Status status={displayStatus} type={STATUS_TYPES[status]} />
                )}
              </>
            )
          }
          return { ...cell, renderData }
        }
        return cell
      case 'options':
        if (!cell.renderData) {
          renderData = function renderData(_, item) {
            return (
              <ImagesOptions
                item={item}
                pageList={pageList}
                idKey={idKey}
                disableRestore={disableRestore}
                buttonClassName={s.option_btn}
              />
            )
          }
          return { ...cell, renderData }
        }
        return cell
      case 'os':
        if (!cell.renderData) {
          renderData = function renderData(value, item) {
            const osIcon = getImageIconName(value, darkTheme)

            return (
              <>
                {osIcon ? (
                  <TooltipWrapper
                    className={s.popup}
                    wrapperClassName={s.popup__wrapper}
                    content={`${item.os_distro?.$} ${item.os_version?.$ ?? ''}`}
                  >
                    <img
                      src={require(`@images/soft_os_icons/${osIcon}.png`)}
                      alt={item?.os_distro?.$}
                    />
                  </TooltipWrapper>
                ) : null}
              </>
            )
          }
          return { ...cell, renderData }
        }
        return cell
      case 'price_per_day':
        if (!cell.renderData) {
          renderData = function renderData(_, item) {
            const sizeCell = cells.find(el => el.label === 'size')

            return item[sizeCell?.value]?.$
              ? `€${roundToDecimal(
                  Math.ceil(item[sizeCell?.value]?.$) * Number(cost.stat_cost.$),
                  undefined,
                  3,
                )}`
              : ''
          }
          return { ...cell, renderData }
        }
        return cell
      case 'size':
        if (!cell.renderData) {
          renderData = function renderData(value) {
            return value ? (
              <>
                <div data-td-value-top>{`${value} GB`}</div>
                <div className="text-dark-50">
                  {`${Math.ceil(value) * Number(cost.stat_cost.$)} EUR/ ${t('day')}`}
                </div>
              </>
            ) : (
              ''
            )
          }
          return { ...cell, renderData }
        }
        return cell
      case 'data_center':
        if (!cell.renderData) {
          renderData = function renderData(value, item) {
            const itemCountry = formatCountryName(item, t, 'region')

            return (
              <TooltipWrapper
                className={s.popup}
                wrapperClassName={cn(s.popup__wrapper, s.popup__wrapper_flag)}
                content={itemCountry?.DCName}
              >
                {itemCountry ? (
                  <img
                    src={require(`@images/countryFlags/${getFlagFromCountryName(
                      itemCountry?.countryName,
                    )}.png`)}
                    width={20}
                    height={20}
                    alt={value}
                  />
                ) : (
                  'undefined'
                )}
              </TooltipWrapper>
            )
          }
          return { ...cell, renderData }
        }
        return cell
      case 'type':
        if (!cell.renderData) {
          renderData = function renderData(value) {
            return t(value)
          }
          return { ...cell, renderData }
        }
        return cell
      case 'backup_type':
        renderData = value => value && t(value)

        return { ...cell, renderData }
      case 'created_at':
        if (!cell.renderData) {
          renderData = function renderData(value, item) {
            const { date } = getFormatedDate({ date: value, time: item.i_opentime?.$ })

            return date
          }
          return { ...cell, renderData }
        }
        return cell
      default:
        return cell
    }
  })

  const itemOnClick = (e, item) => itemOnClickHandler?.(e, item, instanceId)
  const isItemClickable = !!itemOnClickHandler

  return (
    <>
      {items && (
        <>
          {items.length ? (
            <div className={s.wrapper}>
              {widerThan1024 ? (
                <Table
                  stickyHeader={stickyHead}
                  isItemsClickable
                  className={s.table}
                  vAlignTop
                >
                  <thead>
                    <tr>
                      {renderHeadCells()}
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map(item => (
                      <ImageItem
                        key={item?.[idKey].$}
                        item={item}
                        cells={renderCells}
                        itemOnClickHandler={itemOnClick}
                        idKey={idKey}
                        isItemClickable={isItemClickable}
                      />
                    ))}
                  </tbody>
                </Table>
              ) : (
                <CardsContainer className={s.mobile__list}>
                  {items.map(item => {
                    return (
                      <ImageMobileItem
                        key={item?.[idKey].$}
                        item={item}
                        cells={renderCells}
                        itemOnClickHandler={itemOnClick}
                        idKey={idKey}
                        isItemClickable={isItemClickable}
                      />
                    )
                  })}
                </CardsContainer>
              )}
            </div>
          ) : (
            <div className={s.no_images_wrapper}>
              <Icon name="EmptyCloudList" />
              <p className={s.no_images_title}>
                {isFiltered ? t('nothing_found', { ns: 'filter' }) : t('empty_list')}
              </p>
            </div>
          )}
        </>
      )}
    </>
  )
}

ImagesList.propTypes = {
  items: PropTypes.array,
  cells: PropTypes.array,
  getItems: PropTypes.func,
  editImage: PropTypes.func,
  idKey: PropTypes.string,
  pageList: PropTypes.string,
}
