/* eslint-disable no-unused-vars */
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal, Cta } from '@components'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { vdsOperations } from '@redux'

import s from './RebootModal.module.scss'

export default function RebootModal({ id, names, closeModal, isOpen }) {
  const { t } = useTranslation(['vds', 'dedicated_servers', 'other'])
  const dispatch = useDispatch()
  // const [namesOpened, setNamesOpened] = useState(false)
  // const [firstRender, setFirstRender] = useState(true)
  // const namesBlock = useRef()

  const onRebootServer = () => {
    dispatch(vdsOperations.rebootServer(id))
    closeModal()
  }

  // useEffect(() => {
  //   if (namesBlock.current) {
  //     if (!namesOpened) {
  //       namesBlock.current.style.height =
  //         namesBlock.current.firstElementChild.scrollHeight + 'px'
  //       !firstRender && namesBlock.current.firstElementChild.scrollIntoView()
  //     } else {
  //       const openedHeight =
  //         namesBlock.current.scrollHeight > 260
  //           ? 260 + 'px'
  //           : namesBlock.current.scrollHeight + 'px'
  //       namesBlock.current.style.height = openedHeight
  //     }
  //   }
  // }, [namesOpened])

  // useEffect(() => {
  //   setFirstRender(false)
  // }, [])

  const isSingleName = names.length === 1

  return (
    <Modal isOpen={isOpen} closeModal={closeModal} isClickOutside>
      <Modal.Header>
        <div>
          <p className={'headings_h2'}>{t('reload')}</p>

          {isSingleName && <span className={'body_m'}>{names}</span>}
        </div>
      </Modal.Header>
      <Modal.Body>
        <p className={s.text}>
          {t('Are you sure you want to restart the server', { ns: 'dedicated_servers' })}?
        </p>

        {!isSingleName && (
          <p className={cn('body_s', s.warning_text)}>
            {t('Attention, edit few services', { ns: 'other' })}
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className={s.btn_save}
          onClick={onRebootServer}
          label={t('ok', { ns: 'other' })}
        />

        <Cta
          buttonType="button"
          view="secondary"
          onClick={closeModal}
          className={'body_m'}
        >
          {t('Cancel', { ns: 'other' })}
        </Cta>
      </Modal.Footer>
    </Modal>
  )
}
