export default function SvgComponent(props) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="icon/info" clipPath="url(#clip0_3524_30762)">
        <path
          id="Vector"
          d="M7.40078 7.3998L7.43358 7.3838C7.53616 7.33256 7.65129 7.31178 7.76531 7.32393C7.87934 7.33608 7.9875 7.38066 8.07698 7.45237C8.16645 7.52409 8.23351 7.61995 8.27019 7.72859C8.30688 7.83724 8.31167 7.95412 8.28398 8.0654L7.71758 10.3342C7.6897 10.4455 7.69433 10.5625 7.73093 10.6713C7.76754 10.7801 7.83457 10.8761 7.92409 10.9479C8.01361 11.0198 8.12185 11.0644 8.23598 11.0766C8.3501 11.0888 8.46533 11.0679 8.56798 11.0166L8.60078 10.9998M15.2008 7.9998C15.2008 8.94532 15.0145 9.88158 14.6527 10.7551C14.2909 11.6287 13.7605 12.4224 13.0919 13.091C12.4234 13.7596 11.6296 14.2899 10.7561 14.6517C9.88256 15.0136 8.9463 15.1998 8.00078 15.1998C7.05526 15.1998 6.119 15.0136 5.24546 14.6517C4.37192 14.2899 3.57819 13.7596 2.90961 13.091C2.24103 12.4224 1.71068 11.6287 1.34885 10.7551C0.987015 9.88158 0.800781 8.94532 0.800781 7.9998C0.800781 6.09025 1.55935 4.2589 2.90961 2.90864C4.25987 1.55837 6.09122 0.799805 8.00078 0.799805C9.91034 0.799805 11.7417 1.55837 13.0919 2.90864C14.4422 4.2589 15.2008 6.09025 15.2008 7.9998ZM8.00078 4.9998H8.00718V5.0062H8.00078V4.9998Z"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3524_30762">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
